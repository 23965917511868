import { ReactNode } from 'react';
import clsx from 'clsx';

/*
 * Created Date: August 12th 2024, 1:01:31 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 12th 2024, 3:07:18 pm
 */
export default function TaskItem({
  onClick,
  children,
  className,
}: {
  onClick: () => void;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'rounded-lg cursor-pointer flex items-center p-[12px] w-full bg-[#FFF] [box-shadow:0px_1px_4px_0px_rgba(0,_0,_0,_0.40),_0px_-3px_0px_0px_#DCDCDC_inset]',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
