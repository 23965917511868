import { IEnergy, ISpinInfo, spinInfo } from '@/api/game';
import images from '@/const/images';
import { transferSeconds } from '@/utils';
import dayjs from '@/utils/dayjs';
import { useInterval, useRequest } from 'ahooks';
import React, { FC, useState } from 'react';

type Props = {
  energy: IEnergy | undefined;
  changeEnergy: (energy?: IEnergy | undefined) => void;
};
const EnergyInterval: FC<Props> = ({ energy, changeEnergy }) => {
  const [currentTime, setCurrentTime] = useState<number>(0);

  const { refresh: refreshSpinInfo } = useRequest(
    async () => {
      const result: ISpinInfo = await spinInfo();
      changeEnergy(result.energy);
    },
    {
      cacheKey: 'spinInfo',
    }
  );
  useInterval(
    () => {
      const time = energy?.energy_next_updated;
      const currentEnergy = energy?.energy;
      if (currentEnergy !== undefined && currentEnergy < 50) {
        const nextCurrent = dayjs(time).unix() - dayjs().unix();
        setCurrentTime(nextCurrent);
        if (nextCurrent <= 1) {
          refreshSpinInfo();
        }
      }
    },
    1000,
    { immediate: true }
  );
  return (
    <div className="relative w-[306px] h-[78px] mt-[-8px] z-[5]">
      <img
        className="w-full h-full"
        src={images.game.spin.pedestalFront}
        alt=""
      />
      <div className="absolute gap-y-[6px] flex flex-col items-center justify-center w-full h-full top-[-10px] left-0">
        <div className="flex gap-x-1 items-center">
          <div className="relative overflow-hidden flex items-center justify-center ml-[10px] bg-[#E3D3BE] border-[2px] border-solid border-[#E9DBC5] [box-shadow:inset_0px_4px_4px_0px_rgba(146,_135,_106,_0.3)] rounded-[12px]">
            <span className="[white-space:pre] w-[128px] text-[10px] overflow-hidden text-ellipsis font-rubik font-bold text-center opacity-50 z-[1] text-[#040404]">
              {Number(energy?.energy ?? 0)} / 50
            </span>

            <div
              className="h-[16px] bg-[#FFBC28] absolute top-0 left-0  rounded-[12px] [box-shadow:inset_0px_-2px_0px_0px_rgba(0,0,0,_0.12)] "
              style={{
                width: `${(Number(energy?.energy ?? 0) / 50) * 100}%`,
              }}
            ></div>
          </div>
          <img
            className="w-[21px] h-[21px]"
            src={images.game.spin.energyIcon}
            alt=""
          />
        </div>
        {(energy?.energy || 0) < 50 && (
          <span className="font-rubik text-[8px] text-align-center leading-[9px] capitalize text-[#827867]">
            <span className="font-[700]">5</span> spins ready in{' '}
            <span className="font-[700]">{transferSeconds(currentTime)}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(EnergyInterval);
