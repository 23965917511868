import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import ky, { Options } from 'ky';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store from 'store';
import * as Sentry from '@sentry/react';

export interface BaseResponse<T> {
  code: number;
  data: T;
  message: string;
}

export const api = ky.create({
  prefixUrl: import.meta.env.VITE_BASE_URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        const { initDataRaw } = retrieveLaunchParams();
        // 开发时可以通过env中的VITE_RAWDATA来模拟联调
        request.headers.set(
          'rawdata',
          import.meta.env.DEV && import.meta.env.VITE_RAWDATA
            ? import.meta.env.VITE_RAWDATA
            : initDataRaw?.toString()
        );

        try {
          if (!store.get('fpHash')) {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            request.headers.set('X-DEVICE-ID', visitorId);
            store.set('fpHash', visitorId);
          } else {
            request.headers.set('X-DEVICE-ID', store.get('fpHash'));
          }
        } catch (error) {}
      },
    ],
    beforeError: [
      async (error) => {
        const { response, message, request } = error;

        const res: any = await response.json();

        if (response && response.body) {
          error.message = res?.data?.err || message;
        }

        // 错误请求上报
        try {
          const { version, platform, initData } = retrieveLaunchParams();
          Sentry.captureException(error, {
            contexts: {
              message: {
                url: request.url,
                platform,
                version,
                initData: JSON.stringify(initData),
                rawdata: request.headers.get('rawdata'),
                data: await request.text(),
                method: request.method,
                status: response.status,
                responseData: await response.text(),
              },
            },
          });
        } catch (err) {}

        return error;
      },
    ],
    afterResponse: [
      (_request, _options, response) => {
        if (response.status === 429) {
          throw new Error();
        }
      },
    ],
  },
});

async function request<T>(
  method: 'get' | 'post',
  url: string,
  options?: Options
) {
  const response = await api[method](url, options);
  const resData: BaseResponse<T> = await response.json();
  if (resData.code === 400) {
    throw new Error(resData.message);
  }
  return resData.data as T;
}

export default request;
