import Modal, { ModalRef } from '@/components/Modal';
import SoundVolume from '@/components/SoundVolume';
import SwitchButton from '@/components/SwitchButton';
import images from '@/const/images';
import React, { FC } from 'react';

type Props = {
  volumeModal: React.MutableRefObject<ModalRef | undefined>;
};
const VolumeModal: FC<Props> = ({ volumeModal }) => {
  return (
    <Modal ref={volumeModal} icon={images.common.volume}>
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-xl font-changa-one text-black">
          Volume adjustment
        </h3>
        <p className="font-poppins text-[14px] text-black opacity-30 w-[260px] mx-auto">
          Please drag to adjust
        </p>
        <div className="w-[291px] flex-col flex justify-center p-[18px] gap-y-[12px]  my-[10px] mx-auto bg-[#F2F0FA] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px] [box-shadow:inset_0px_3px_0px_0px_rgba(0,0,0,0.03)]">
          <div className="flex items-center gap-x-[20px]">
            <span className="text-[16px] font-changa-one text-black">
              Vibrate
            </span>
            <SwitchButton />
          </div>
          <div className="flex items-center justify-between gap-x-[12px]">
            <span className="text-[16px] font-changa-one text-black">
              Sound
            </span>
            <SoundVolume />
          </div>
        </div>
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-bold font-changa-one"
          onClick={() => {
            volumeModal.current?.hide();
          }}
        >
          confirm
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(VolumeModal);
