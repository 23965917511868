import { getInvitationInfo, IInvitationRecord } from '@/api/invite';
import { useLoading } from '@/components/LoadingProvider';
import images from '@/const/images';
import { DaysType, formatCurrency, transferDays } from '@/utils';
import dayjs from '@/utils/dayjs';
import logEvent, { EventName } from '@/utils/firebase';
import { postEvent } from '@telegram-apps/sdk-react';
import { useInterval, useRequest } from 'ahooks';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TonBalance = ({
  invitationRecord,
}: {
  invitationRecord: IInvitationRecord | undefined;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useLoading();
  const [current, setCurrent] = useState<DaysType>();

  const { data: invitationInfo } = useRequest(
    async () => {
      const invitationRecord = await getInvitationInfo();
      return invitationRecord;
    },
    {
      cacheKey: 'inviteInfo',
      onBefore: () => {
        if (!invitationInfo) {
          setIsLoading(true);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const goTonOverview = () => {
    navigate('/tonOverview', { state: location.pathname });
  };

  const goInviteRules = () => {
    navigate('/inviteRules');
    logEvent(EventName.INVITE_RULE_CLICK, {
      rule_click: 'rule',
    });
  };
  const openProof = () => {
    postEvent('web_app_open_link', {
      url: 'https://tonviewer.com/UQBcCxBQ52ql0f3cho8Vit-uMMNTjc9S41TXjyk_QSnKZa76',
    });
  };

  useInterval(
    () => {
      if (invitationInfo) {
        const values = transferDays(
          dayjs(invitationInfo?.end_time_stamp * 1000).unix() - dayjs().unix()
        );
        setIsLoading(false);
        setCurrent(values);
      }
    },
    1000,
    {
      immediate: true,
    }
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex w-[183px] flex-col [background:#4D5055] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.40),0px_-3px_0px_0px_rgba(0,0,0,0.15)_inset] rounded-[10px] gap-[12px] pt-[10px] pb-[12px] px-[12px]">
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[4px]">
              <p className="font-poppins text-white text-[10px] text-left opacity-60">
                Prize Pool
              </p>
              <h2 className="font-changa-one flex items-center gap-[4px] text-white text-[24px] mb-[5px] text-xl font-normal leading-[normal] capitalize">
                <img
                  className="w-[20px] h-[20px]"
                  width={20}
                  height={20}
                  src={images.game.invite.tonIcon}
                  alt=""
                />
                {formatCurrency(invitationInfo?.total_ton_reward, true)}
              </h2>
            </div>
            <div className="flex flex-col gap-[12px]">
              <div className="flex items-center justify-between">
                <span className="text-white font-poppins text-[10px] font-medium leading-[14px] capitalize opacity-60">
                  Pool Left
                </span>
                <span className="text-white font-changa-one text-sm font-normal leading-[normal] capitalize mt-[-2px]">
                  {formatCurrency(invitationInfo?.remaining_ton_reward, true)}{' '}
                  TON
                </span>
              </div>
              <div className="flex gap-[10px]">
                <button
                  className="flex justify-center items-center gap-2.5 flex-[1_0_0] [background:rgba(255,255,255,0.20)] shadow-[0px_-0.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] px-3 py-[5px] rounded-md font-changa-one text-white text-center text-xs font-normal leading-[14px] capitalize"
                  onClick={goInviteRules}
                >
                  Rules
                </button>
                <button
                  className="flex justify-center items-center gap-1 flex-[1_0_0] [background:#FFF] shadow-[0px_-0.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] px-3 py-[5px] rounded-md font-changa-one text-black text-center text-xs font-normal leading-[14px] capitalize"
                  onClick={openProof}
                >
                  Proof
                  <img
                    className="w-[15px] h-[15px]"
                    width={15}
                    height={15}
                    src={images.game.invite.prizePoolForwardIcon}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[140px] h-[138px] justify-between items-start border [background:#FFF] shadow-[0px_-3px_0px_0px_#DCDCDC_inset,0px_1px_4px_0px_rgba(0,0,0,0.40)] pt-[10px] pb-[12px] px-[12px] rounded-[10px] border-solid ">
          <div className="text-left">
            <p className="font-changa-one text-[14px] text-black">Balance</p>
            <h3 className="flex items-center gap-[4px] font-changa-one text-[20px] text-black font-normal leading-[normal] capitalize">
              <img
                className="w-[20px] h-[20px]"
                width={20}
                height={20}
                src={images.game.invite.tonIcon}
                alt=""
              />
              {invitationRecord?.ton_balance
                ? parseFloat(invitationRecord?.ton_balance.toFixed(4) as string)
                : '0.0000'}
            </h3>
          </div>
          <button
            className="px-[16px] py-[8px] h-[34px] rounded-[8px] w-full border border-[#00CD00] font-changa-one text-[14px] text-[#00CD00]  text-center  font-normal leading-[14px] capitalize"
            onClick={goTonOverview}
          >
            Cash Out
          </button>
        </div>
      </div>

      <div className="flex w-full justify-between h-[42px] items-center border [background:#EFD6BF] px-[12px] py-[8px] rounded-lg border-solid border-[#ECCFB5]">
        {current && !current.isOver ? (
          <>
            <span className="text-[#826143] font-changa-one text-[12px] font-normal leading-[14px]">
              Cash Out Open
            </span>
            <div className="flex items-center gap-[4px] text-white font-changa-one text-[12px] font-normal">
              <span
                className={clsx(
                  'flex leading-4 justify-center mr-[4px] items-center shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-[6px]',
                  current.days === 0 ? 'bg-[#F75F21]' : 'bg-[#4D5055]'
                )}
              >
                {current.days} Days
              </span>
              <span
                className={clsx(
                  'flex leading-4 justify-center mr-[4px] items-center shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-[6px]',
                  current.days === 0 ? 'bg-[#F75F21]' : 'bg-[#4D5055]'
                )}
              >
                {current.hours}
              </span>
              <span
                className={clsx(
                  'font-changa-one text-[14px] font-normal leading-[14px] tracking-[0.84px]',
                  current.days === 0 ? 'text-[#F75F21]' : 'text-[#4D5055]'
                )}
              >
                :
              </span>
              <span
                className={clsx(
                  'flex leading-4 justify-center mr-[4px] items-center shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-[6px]',
                  current.days === 0 ? 'bg-[#F75F21]' : 'bg-[#4D5055]'
                )}
              >
                {current.minutes}
              </span>
              <span
                className={clsx(
                  'font-changa-one text-[14px] font-normal leading-[14px] tracking-[0.84px]',
                  current.days === 0 ? 'text-[#F75F21]' : 'text-[#4D5055]'
                )}
              >
                :
              </span>
              <span
                className={clsx(
                  'flex leading-4 justify-center mr-[4px] items-center shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-[6px]',
                  current.days === 0 ? 'bg-[#F75F21]' : 'bg-[#4D5055]'
                )}
              >
                {current.seconds}
              </span>
            </div>
          </>
        ) : (
          <div className="flex items-center gap-[8px]">
            <img
              src={images.game.invite.prizePoolIcon}
              alt=""
              className="w-[28px] h-[28px]"
              width={28}
              height={28}
            />
            <div className="flex flex-col gap-[4px]">
              <span className="text-[#826143] font-changa-one text-[12px] font-normal leading-[14px] text-left">
                Cash Out are now open
              </span>
              <span className="text-[#826143] font-rubik text-[10px] font-normal leading-[12px] opacity-70">
                For a limited time, withdraw your TON now!
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(TonBalance);
