// @ts-ignore
import { OKXUniversalProvider } from '@okxconnect/universal-provider';
import { useMemo, useState } from 'react';

type ConnectNamespace = {
  chains: string[];
  rpcMap?: Record<string, string>;
  defaultChain?: string;
};

type ConnectParams = {
  namespaces: Record<string, ConnectNamespace>;
  optionalNamespaces?: Record<string, ConnectNamespace>;
  sessionConfig: {
    redirect: string;
  };
};

type DappInfo = { name: string; icon: string };

type SessionStruct = {
  topic: string;
  namespaces: Record<
    string,
    ConnectNamespace & {
      accounts: string[];
      methods: string[];
    }
  >;
  sessionConfig?: {
    dappInfo: DappInfo;
    redirect?: string;
  };
};

enum OKXChain {
  EVM = 'eip155',
}

type OKXUniversalProviderType = {
  connect: (params: ConnectParams) => Promise<SessionStruct>;
  init: (params: {
    dappMetaData: DappInfo;
  }) => Promise<OKXUniversalProviderType>;
};

let provider: OKXUniversalProviderType | null = null;

(async () => {
  try {
    const _provider = await (
      OKXUniversalProvider as unknown as OKXUniversalProviderType
    ).init({
      dappMetaData: {
        name: 'tabizoo',
        icon: 'https://front.tabibot.com/tabi_icon.webp',
      },
    });
    provider = _provider;
  } catch (error: any) {
    console.warn(error.message ?? error);
  }
})();

const useOKXConnect = () => {
  const [session, setSession] = useState<SessionStruct>();
  const connectOKX = async () => {
    try {
      const payload = await provider?.connect({
        namespaces: {
          eip155: {
            chains: ['eip155:1'],
          },
        },
        sessionConfig: {
          redirect: 'tg://resolve',
        },
      });
      setSession(payload);
    } catch (error: any) {
      console.warn(error.message ?? error);
    }
  };

  const isConnected = useMemo(() => {
    if (session) {
      return !!session.namespaces?.[OKXChain.EVM].accounts;
    }
    return false;
  }, [session]);

  return {
    connectOKX,
    isConnected,
  };
};

export default useOKXConnect;