import { ILevelConfig, IUserProfile } from '@/api/game';
import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React from 'react';
import Process from '../Process';

type Props = {
  extendsBModal: React.MutableRefObject<ModalRef | undefined>;
  userProfile: IUserProfile | undefined;
  level: ILevelConfig | undefined;
};

const ExtendsBModal: React.FC<Props> = ({
  extendsBModal,
  userProfile,
  level,
}) => {
  return (
    <Modal
      ref={extendsBModal}
      iconChildren={
        <img
          className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[132px]"
          src={images.game.upgradeB}
        />
      }
    >
      <div className="w-[339px] pb-[30px] shrink-0 [background:linear-gradient(183.78deg,rgba(255,62,1,0.16)_3.1%,rgba(255,255,255,0.00)_60.92%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-xl font-changa-one text-black mt-[12px]">
          Evolution Successful!
        </h3>
        <div className="text-[12px] mt-[12px] font-rubik text-black">
          Your Shiro will go directly to{' '}
          <span className="text-[#00AE00] font-changa-one font-bold">
            LV.{userProfile?.user.level}
          </span>
          .
        </div>
        <div className="w-[267px] mx-auto [box-shadow:inset_0px_3px_0px_rgba(0,0,0,_0.03)] my-[12px] rounded-[10px] bg-[rgba(0,0,0,_0.04)] [border:1.4px_solid_#EAE7F2] flex items-center justify-center p-[12px] gap-x-[18px]">
          <div className="flex-1 flex justify-center  gap-y-[8px]">
            <img
              src={images.game.levelOldIcon}
              className="w-[20px] h-[20px]"
              alt=""
              width={20}
              height={20}
            />
            <p className="flex flex-col ">
              <span className="font-rubik text-[12px] text-black opacity-50 mt-[1px]">
                Original level
              </span>
              <span className="text-[16px] text-start font-changa-one text-black">
                Lv.{userProfile?.user.orign_level}
              </span>
            </p>
          </div>
          <img
            src={images.game.arrow}
            className="w-[12px] h-[12px]"
            alt=""
            width={12}
            height={12}
          />

          <div className="flex-1 flex justify-center gap-x-[2px]">
            <img
              src={images.game.levelNewIcon}
              className="w-[20px] h-[20px]"
              alt=""
              width={20}
              height={20}
            />
            <div className="flex flex-col">
              <span className="font-rubik text-start text-[12px] text-black opacity-50 mt-[1px]">
                New Level
              </span>
              <h3 className="flex">
                <div className="flex flex-col items-start">
                  <span className="text-[20px] font-changa-one text-black mt-[-4px]">
                    Lv.{userProfile?.user.level}
                  </span>
                  <Process level={level} whiteColor='bg-[rgba(0,0,0,0.20)]' />
                </div>
                <img
                  src={images.game.upgradeArrow}
                  className="w-[9px] h-[9px] mt-[2px] ml-[-2px]"
                  alt=""
                  width={9}
                  height={9}
                />
              </h3>
            </div>
          </div>
        </div>
        <p className="font-rubik text-[12px] mt-[12px] text-black opacity-50 w-[299px] mx-auto">
          Please note that after Shiro evolves, your remaining coins will be
          converted into new levels.
        </p>
        <div
          onClick={() => {
            extendsBModal.current?.hide();
          }}
          className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[15px] text-nowrap font-bold font-changa-one"
        >
          Mining Now
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ExtendsBModal);
