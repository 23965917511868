import {
  ILevelConfigList,
  IUserProfile,
} from '@/api/game';
import BasicLayout from '@/layout/basicLayout';
import TabbarLayout from '@/layout/tabbarLayout';
import ErrorBoundary from '@/components/ErrorBoundary';
import Index from '@/pages/Index';
import Invite from '@/pages/Invite';
import Spin from '@/pages/Spin';
import Mine from '@/pages/Mine';
import Task from '@/pages/Task';
import Upgrading from '@/pages/Upgrading';
// import Profile from '@/pages/Profile';
import { createBrowserRouter } from 'react-router-dom';
import lazyRouters from './lazyRouters';

export interface IRouterRootLoaderData {
  userProfile: IUserProfile | undefined;
  levelConfigs: ILevelConfigList | undefined;
}

const router = createBrowserRouter([
  {
    path: '/',
    id: 'root',
    element: <BasicLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <TabbarLayout />,
        children: [
          {
            index: true,
            path: 'index',
            element: <Index />,
          },
          {
            path: 'mine',
            element: <Mine />,
          },
          {
            path: 'invite',
            element: <Invite />,
          },
          {
            path: 'task',
            element: <Task />,
          },
          {
            path: 'spin',
            element: <Spin />,
          },
        ],
      },
      ...lazyRouters,
      {
        path: '/upgrading',
        element: <Upgrading />,
      },
    ],
  },
]);

export default router;
