import { VolumeType } from '@/components/SoundVolume';
import { useMemo } from 'react';
import store from 'store';
import useSound from 'use-sound';

const useVolumeSound = (src: string) => {

  const value = useMemo(() => {
    return store.get(VolumeType.Sound);
  }, [store.get(VolumeType.Sound)]);

  const [playAudio] = useSound(src, {
    volume: (value ?? 100) / 100,
  });

  return {
    playAudio,
  };
};

export default useVolumeSound;
