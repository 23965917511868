import { ILevelConfig } from '@/api/game';
import { getLevelCount } from '@/pages/Upgrade/config';
import clsx from 'clsx';
import { FC, memo, useMemo } from 'react';

type Props = {
  level: ILevelConfig | undefined;
  whiteColor?: string;
};
const Process: FC<Props> = ({ level, whiteColor }) => {
  const values = useMemo(() => {
    return getLevelCount(level);
  }, [level]);
  return (
    <div className="inline-flex items-center gap-x-[1px]">
      {values.map((item, index) => (
        <span
          key={index}
          className={clsx(
            'w-[8px] h-[4px] rounded-[2px]',
            item
              ? 'bg-[#FF6121] [box-shadow:0px_-0.8px_0px_0px_#DB4E14_inset]'
              : (whiteColor ?? 'bg-[rgba(255,255,255,0.50)]')
          )}
        ></span>
      ))}
    </div>
  );
};

export default memo(Process);
