import { useEffect, useRef, useState } from 'react';
import { SubTaskEntity, TaskType, uploadCount } from '@/api/mine';
import { verifyTask } from '@/api/task';
import images from '@/const/images';
import { postEvent } from '@telegram-apps/sdk-react';
import { useRequest } from 'ahooks';
import clsx from 'clsx';
import useOKXConnect from '@/hooks/useOkxConnect';
import useIsApp from '@/hooks/useIsApp';
import store from 'store';
import Loading from '../Loading';
import Modal, { ModalRef } from '../Modal';
import Toast, { ToastRef } from '../Toast';
import logEvent, { EventName } from '@/utils/firebase';
import useProfile from '@/hooks/useProfile';

/*
 * Created Date: August 11th 2024, 10:49:52 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: October 17th 2024, 3:53:27 pm
 */

enum Step {
  Go = 'Go',
  Check = 'Check',
  Confirm = 'Confirm',
}

interface IStory {
  media_url: string;
  text: string;
}

export default function TaskModal({
  task,
  modalRef,
  refresh,
}: {
  task: SubTaskEntity;
  modalRef: React.MutableRefObject<ModalRef | undefined>;
  refresh: () => void;
}) {
  const [step, setStep] = useState<Step>(Step.Go);

  const { connectOKX, isConnected } = useOKXConnect();

  const isApp = useIsApp();

  const toastRef = useRef<ToastRef>();

  const { run: getProfile } = useProfile();

  const { run, loading } = useRequest(
    async () => {
      try {
        const res = await verifyTask({ task_tag: task.task_tag });

        if (res.code !== 200 || !res.data?.verify) {
          if (task?.task_type !== TaskType.Invite3Person) {
            setStep(Step.Go);
          }

          toastRef.current?.show('error', res.message || 'Check Error');
          return;
        }

        setStep(Step.Confirm);
        getProfile();
        refresh();
      } catch (err: any) {
        if (task?.task_type !== TaskType.Invite3Person) {
          setStep(Step.Go);
        }
        toastRef.current?.show('error', err.message || 'Check Error');
      }
    },
    { manual: true }
  );

  const isConfirm = step === Step.Confirm;

  const handlePostStory = (task: SubTaskEntity) => {
    const story = JSON.parse(task?.ext) as IStory;
    // @ts-ignore
    postEvent('web_app_share_to_story', {
      media_url: story?.media_url,
      text: story?.text,
    });
    uploadCount(task.task_tag);
  };

  useEffect(() => {
    if (task?.task_type === TaskType.Invite3Person) {
      setStep(Step.Check);
    } else {
      setStep(Step.Go);
    }
  }, [task?.task_type]);

  return (
    <Modal
      ref={modalRef}
      iconChildren={
        isConfirm ? (
          <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[262px]">
            <img
              className="w-full"
              src={images.mine.mineClaimSuccessIcon}
              width={100}
              height={100}
            />

            <img
              src={images.mine.mineClaimIcon}
              alt=""
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120px]"
            />
          </div>
        ) : (
          <img
            className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[110px]"
            src={images.task.modalIcon1}
            width={110}
            height={110}
          />
        )
      }
      onClose={() => {
        if (task?.task_type === TaskType.Invite3Person) {
          setStep(Step.Check);
          return;
        }

        setStep(Step.Go);
      }}
    >
      <div
        className={clsx(
          'w-[339px] pb-[20px] px-[24px] shrink-0 shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center',
          isConfirm
            ? '[background:linear-gradient(180deg,rgba(255,62,1,0.16)_0%,rgba(255,255,255,0.00)_100%),#FFF]'
            : '[background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF]'
        )}
      >
        <h3 className="text-xl font-changa-one text-black">
          {isConfirm ? 'You’re received' : task?.task_detail_title}
        </h3>
        <p className="font-poppins text-black text-sm mt-[6px] leading-[14px] opacity-30">
          {task?.task_detail_desc}
        </p>
        <div
          className={clsx(
            'px-[29px] h-[54px] rounded-[10px] border-[1.4px] border-[#E5E3EE] text-[#f75f21] text-xl font-changa-one capitalize leading-[14px] flex items-center justify-center mt-[25px] mx-auto [box-shadow:0px_3px_0px_0px_#E5E3EE_inset] w-fit',
            isConfirm ? 'bg-black/[0.04]' : 'bg-[#F2F0FA]'
          )}
        >
          <img
            src={images.common.coin}
            className="w-[28px] h-[28px] mt-[5px]"
            width={24}
            height={24}
          />
          +{task?.reward || 0}
        </div>
        {step !== Step.Confirm && (
          <>
            <div
              className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[135px] py-3.5 rounded-[47px] mx-auto text-white mt-[25px] text-center font-changa-one text-base leading-[14px] capitalize"
              onClick={() => {
                logEvent(EventName.TASK_POP_CLICK, {
                  pop_name: 'go',
                });
                store.set(task?.task_tag, true);
                if (
                  task?.task_type === TaskType.WebPass ||
                  task?.task_type === TaskType.Carnival
                ) {
                  setStep(Step.Check);
                  postEvent('web_app_open_link', { url: task?.link_url });
                  return;
                }

                if (task?.task_type === TaskType.PostStory) {
                  handlePostStory(task);
                  return;
                }

                if (task?.task_type === TaskType.LaunchOKX) {
                  if (isApp) {
                    connectOKX();
                    setStep(Step.Check);
                  } else {
                    toastRef.current?.show(
                      'error',
                      'Please use the app to connect'
                    );
                  }
                  return;
                }

                setStep(Step.Check);
                postEvent('web_app_open_tg_link', {
                  path_full: task?.link_url,
                });
              }}
            >
              GO
            </div>
            <div
              className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#fff] px-[135px] py-3.5 rounded-[47px] mx-auto text-[#FD6019] border border-[#FD6019] mt-[15px] text-center font-changa-one text-base leading-[14px] capitalize loader-primary"
              onClick={() => {
                logEvent(EventName.TASK_POP_CLICK, {
                  pop_name: 'check',
                });
                if (
                  (step === Step.Go && !store.get(task?.task_tag)) ||
                  (task?.task_type === TaskType.LaunchOKX && !isConnected)
                ) {
                  toastRef?.current?.show('error', 'Task Not Completed');
                  return;
                }

                if (step === Step.Check || store.get(task?.task_tag)) {
                  run();
                  return;
                }
              }}
            >
              {loading ? <Loading /> : 'Check'}
            </div>
          </>
        )}
        {step === Step.Confirm && (
          <div
            className="flex w-[291px] cursor-pointer h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[135px] py-3.5 rounded-[47px] mx-auto text-white mt-[25px] text-center font-changa-one text-base leading-[14px] capitalize"
            onClick={() => {
              modalRef.current?.hide();
              setStep(Step.Go);
              getProfile();
              store.remove(task?.task_tag);
            }}
          >
            Confirm
          </div>
        )}
      </div>

      <Toast ref={toastRef}></Toast>
    </Modal>
  );
}
