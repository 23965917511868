import images from '@/const/images';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import {
  getBanners,
  getRewardProjects,
  RedirectType,
  Status,
} from '@/api/mine';
import dayjs from '@/utils/dayjs';
import { formatUnixTimestamp } from '@/utils/time';
import { postEvent } from '@telegram-apps/sdk-react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import TaskItem from '@/components/TaskItem';
import logEvent, { EventName } from '@/utils/firebase';
import ImageWithDefault from '@/components/ImageWithDefault';
import { useLoading } from '@/components/LoadingProvider';

export default function Mine() {
  const { setIsLoading } = useLoading();
  const { data: projects } = useRequest(getRewardProjects, {
    cacheKey: 'rewardProjects',
    onBefore: () => {
      if (!projects) {
        setIsLoading(true);
      }
    },
    onFinally: () => {
      setIsLoading(false);
    },
  });

  const { data: banners } = useRequest(getBanners, {
    cacheKey: 'banners',
  });

  const navigate = useNavigate();

  return (
    <div className="min-h-screen pb-[120px] box-border bg-white">
      <Swiper
        modules={[Pagination, Autoplay]}
        autoplay
        loop
        pagination={{ clickable: true }}
        className="h-[230px]"
      >
        {banners?.map?.((item, index) => (
          <SwiperSlide key={index}>
            <img
              className="w-full h-[197px] "
              src={item.image_url}
              alt=""
              onClick={() => {
                // uploadCount(item.id + '');
                logEvent(EventName.EXCHANGE_BANNER_CLICK, {
                  banner_id: `${item.id}`,
                  banner_name: `${item.title}`,
                });

                if (item.redirect_type === RedirectType.Project) {
                  navigate(`/mine/project/${item.redirect_url}`);
                  return;
                }

                if (item.redirect_type === RedirectType.Web) {
                  postEvent('web_app_open_link', { url: item.redirect_url });
                  return;
                }

                if (item.redirect_type === RedirectType.Internal) {
                  postEvent('web_app_open_tg_link', {
                    path_full: item.redirect_url,
                  });
                  return;
                }
              }}
              width={375}
              height={197}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="px-[20px]">
        <h3 className="text-lg text-black m-0 font-changa-one">PROJECT</h3>

        {(projects?.length || 0) > 0 ? (
          <div className="py-[16px] flex flex-col gap-[16px]">
            {projects?.map?.((item, index) => {
              const isSuccess = item.user_project_status === Status.Success;

              return (
                <TaskItem
                  key={index}
                  onClick={() => {
                    navigate(`/mine/project/${item.project_tag}`);
                    logEvent(EventName.EXCHANGE_VIEW_CLICK, {
                      view_id: `${item.id}`,
                      view_name: `${item.project_name}`,
                    });

                    logEvent(EventName.TASK_CONTENT_CLICK, {
                      click_name: 'project',
                      task_name: `${item.project_name}`,
                    });
                  }}
                  className={isSuccess ? 'opacity-50' : ''}
                >
                  <div className="w-full">
                    <div className="flex justify-between items-center font-changa-one">
                      <div className="flex items-center gap-[14px]">
                        <ImageWithDefault
                          className="w-[38px] h-[38px] rounded-[5px]"
                          src={item?.head_img_url || ''}
                          width={38}
                          height={38}
                          style={{
                            boxShadow:
                              '0px 1px 1px 0px rgba(110, 110, 110, 0.25)',
                          }}
                        />
                        <div className="flex flex-col justify-between gap-[2px]">
                          <div className="text-base text-black">
                            {item?.project_name}
                          </div>
                          <div className="text-orange-600 flex items-center">
                            <img
                              className="w-[16px] h-[16px] mt-[2px]"
                              src={images.common.coin}
                              width={16}
                              height={16}
                            />
                            +{item?.reward}
                          </div>
                        </div>
                      </div>
                      {isSuccess ? (
                        <div className="w-[88px] h-[28px] rounded-lg bg-white flex justify-center items-center text-[#00CD00] text-[14px] leading-[14px] [box-shadow:0px_0.4px_1px_0px_rgba(0,_0,_0,_0.34),_0px_-0.8px_0px_1px_rgba(0,_0,_0,_0.13)_inset]">
                          Done
                        </div>
                      ) : (
                        <div className="w-[88px] h-[28px] rounded-lg bg-[#00CD00] flex justify-center items-center text-white text-[14px] leading-[14px] [box-shadow:0px_0.4px_1px_0px_rgba(0,_0,_0,_0.34),_0px_-0.8px_0px_1px_rgba(0,_0,_0,_0.13)_inset]">
                          View
                        </div>
                      )}
                    </div>
                    {!!item.time_limit && (
                      <div className="mt-[14px] pt-[9px] flex items-center border-t border-solid border-[rgba(0,0,0,0.1)] gap-1">
                        <img
                          src={images.mine.timeIcon}
                          className="w-[14px] h-[14px]"
                          width={14}
                          height={14}
                        />
                        <span className="text-black text-xs opacity-30 leading-[12px]">
                          {formatUnixTimestamp(
                            dayjs(item.end_time || 0).unix() - dayjs().unix()
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </TaskItem>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-[8px] pt-[60px]">
            <img
              src={images.mine.empty}
              alt=""
              width={120}
              height={120}
              className="w-[120px]"
            />

            <div className="text-[#C1C1C1] font-changa-one text-sm leading-[14px] capitalize">
              No project yet
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
