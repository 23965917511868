/*
 * Created Date: August 9th 2024, 5:33:00 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 9th 2024, 5:33:00 pm
 */
export function formatUnixTimestamp(timestamp: number) {
  const seconds = timestamp;
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${days}d ${hours}h ${minutes}m`;
}
