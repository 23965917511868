import { useMemo } from 'react';
import { postEvent } from '@telegram-apps/sdk-react';

export default function useTGInvite(userId: number | undefined) {
  const inviteUrl = useMemo(
    () =>
      `https://t.me/${import.meta.env.VITE_BOTID}/${
        import.meta.env.VITE_APPNAME
      }?startapp=${userId}`,
    [import.meta, userId]
  );

  const callback = () => {
    postEvent('web_app_open_tg_link', {
      path_full: `/share/url?url=${inviteUrl}&text=${import.meta.env.VITE_INVITE_DESC}`,
    });
  };

  return {
    callback,
    inviteUrl,
  };
}
