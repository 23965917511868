import request from '.';

export enum InviteRewardTypeEnum {
  Coin = '',
  Energy = 'Energy',
}

export interface IInvitationRecord {
  ton_balance: number;
  invite_reward: number;
  invite_premium_reward: number;
  list: {
    id: number;
    is_premium: number;
    invitee: number;
    name: string;
    referral: number;
    status: number;
    create_time: string;
    confirmed_time: string;
    ton_reward: number;
    is_ton_reward: number;
    invite_reward_type: InviteRewardTypeEnum;
  }[];
}

export interface IInvitationInfo {
  total_ton_reward: number;
  remaining_ton_reward: number;
  end_time: string;
  end_time_stamp: number;
  remaining_time_layout: string;
}

export enum TonTiersStatusEnum {
  Available = 1,
  Unavailable = 2,
}

export interface ITonTier {
  id: number;
  title: string;
  amount: number;
  status: TonTiersStatusEnum;
  item_order: number;
}

export interface ITonTiers {
  Tiers: ITonTier[];
}

export enum TonWithdrawalEnum {
  Success = 1,
  Fail = 2,
  Checking = 4,
}

export interface ICashOut {
  status: CashOutStatusEnum;
}

export enum CashOutStatusEnum {
  Success = 1,
  PartnerBlock = 2,
  AccountBlock = 3,
  TodayQuota = 5,
}

export interface ITonCashoutActivityStatus {
  status: TonCashoutActivityStatusEnum;
}

export enum TonCashoutActivityStatusEnum {
  Open = 1,
  Close = 2,
}

export interface ITonWithdrawalRecord {
  list: {
    id: number;
    cashout_time: number;
    finish_time: number;
    status: TonWithdrawalEnum;
    address: string;
    amount: number;
    gas_fee: number;
  }[];
}

export const getInvitationRecord = async () => {
  const res: IInvitationRecord = await request('get', 'invitation/v1/page');
  return res;
};

export const getTonTiers = async () => {
  const res: ITonTiers = await request('post', 'ton/v1/tiers');

  return {
    ...res,
    tiers: res?.Tiers?.filter(
      (tiers) => tiers?.status === TonTiersStatusEnum.Available
    ),
  };
};

export const cashOut = async (params: { tiers_id?: number }) => {
  const res: ICashOut = await request('post', 'ton/v1/cashout', {
    json: params,
  });
  return res;
};

export const getTonWithdrawalRecord = async () => {
  const res: ITonWithdrawalRecord = await request(
    'post',
    'ton/v1/list_cashout_record'
  );
  return res;
};

export const getTonCashoutActivityStatus = async () => {
  const res: ITonCashoutActivityStatus = await request(
    'post',
    'ton/v1/query_ton_cashout_activity_status'
  );
  return res;
};

export const getInvitationInfo = async () => {
  const res: IInvitationInfo = await request('get', 'invitation/v1/info');
  return res;
};
