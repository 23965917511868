import { useEffect, useMemo, useRef } from 'react';
import images from '@/const/images';
import clsx from 'clsx';
import { getInnerWidthRatio, pxToVw } from '@/utils';
import { getDynamicUrls } from '../config';

const SpinItem = ({
  isSpin,
  currentNo = 6,
  col,
}: {
  isSpin: boolean;
  col: number;
  currentNo?: number;
}) => {
  const isMountedRef = useRef(false);
  const slot = useMemo(() => {
    return [
      ...images.game.spin.slot.slice(-1),
      ...images.game.spin.slot,
      images.game.spin.slot[0],
    ];
  }, []);

  const bgUrls = useMemo(() => {
    return getDynamicUrls(col);
  }, []);

  useEffect(() => {
    if (isSpin && !isMountedRef.current) {
      isMountedRef.current = true;
    }
  }, [isSpin]);

  return (
    <div
      className="flex-1 h-full flex items-center justify-center"
      style={{
        background: bgUrls[0],
      }}
    >
      <div
        className="w-[68px] h-[116px] flex items-center justify-center"
        style={{
          background: bgUrls[1],
        }}
      >
        <div
          className="w-[58px] h-[106px]"
          style={{
            background: bgUrls[2],
          }}
        >
          <div
            className="h-full w-[58px] flex items-center justify-center"
            style={{
              background: bgUrls[3],
            }}
          >
            <div
              className={clsx(
                'h-full w-full flex items-center overflow-hidden',
                col === 0 && 'ml-[2px]',
                col === 2 && 'mr-[2px]'
              )}
              style={{
                background: bgUrls[4],
              }}
            >
              <ul
                className={clsx(
                  'w-full h-full list-none p-0',
                  isSpin && 'animate-scroll-spin'
                )}
                style={{
                  transform: `translateY(-${pxToVw(Math.max(30, currentNo * Math.floor(53 * getInnerWidthRatio()) + 30))})`,
                }}
              >
                {slot.map((icon, index) => (
                  <li
                    key={icon + index}
                    className={clsx(
                      'w-full my-2 flex items-center justify-center',
                      index !== currentNo + 1 && 'opacity-50 scale-90',
                      !isSpin && isMountedRef.current && 'animate-scroll-dump'
                    )}
                  >
                    <img
                      className="w-[45px] h-[45px] object-contain"
                      src={icon}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpinItem;
