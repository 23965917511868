import { useCountDown } from 'ahooks';

const CountDownTimer = ({
  targetDate,
  onEnd,
}: {
  targetDate: string;
  onEnd?: () => void;
}) => {
  const [, formattedRes] = useCountDown({
    targetDate,
    onEnd,
  });

  const formatCount = (count: number): string => {
    if (count < 10) {
      return `0${count}`;
    }
    return `${count}`;
  };

  return (
    <>{`${formatCount(formattedRes?.hours)}:${formatCount(formattedRes?.minutes)}:${formatCount(formattedRes?.seconds)}`}</>
  );
};

export default CountDownTimer;

