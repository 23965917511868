import { confetti } from '@tsparticles/confetti';
import energy from '@/assets/images/spinResultAnimate/energy.png';
import crystal from '@/assets/images/spinResultAnimate/crystal.png';
import zoo from '@/assets/images/spinResultAnimate/zoo.png';
import coin from '@/assets/images/spinResultAnimate/coin.png';
import bear from '@/assets/images/spinResultAnimate/bear.png';
import useVolumeSound from './useVolumeSound';

enum OrderEnum {
  MORE = 210,
  MIDDLE = 110,
  LESS = 10,
}

interface IRule {
  [key: number]: {
    type: any;
    order: OrderEnum;
  };
}

const ruleList: IRule = {
  1: {
    type: energy,
    order: OrderEnum.MORE,
  },
  2: {
    type: crystal,
    order: OrderEnum.MORE,
  },
  3: {
    type: crystal,
    order: OrderEnum.MIDDLE,
  },
  4: {
    type: crystal,
    order: OrderEnum.LESS,
  },
  5: {
    type: zoo,
    order: OrderEnum.MORE,
  },
  6: {
    type: zoo,
    order: OrderEnum.MIDDLE,
  },
  7: {
    type: zoo,
    order: OrderEnum.LESS,
  },
  8: {
    type: coin,
    order: OrderEnum.MORE,
  },
  9: {
    type: coin,
    order: OrderEnum.MORE,
  },
  10: {
    type: coin,
    order: OrderEnum.MORE,
  },
  11: {
    type: bear,
    order: OrderEnum.MORE,
  },
  12: {
    type: coin,
    order: OrderEnum.MIDDLE,
  },
  13: {
    type: coin,
    order: OrderEnum.MIDDLE,
  },
  14: {
    type: coin,
    order: OrderEnum.MIDDLE,
  },
  15: {
    type: coin,
    order: OrderEnum.LESS,
  },
};

const useSpinResultAnimate = () => {
  const { playAudio: playCoinMoreAudio } = useVolumeSound(
    '/audio/spin/coin-more.mp3'
  );
  const { playAudio: playCoinLessAudio } = useVolumeSound(
    '/audio/spin/coin-less.mp3'
  );

  const run = async (rule_id: number) => {
    if (ruleList[rule_id]?.order === OrderEnum.MORE) {
      playCoinMoreAudio();
    } else {
      playCoinLessAudio();
    }

    await confetti({
      spread: 100,
      ticks: 15,
      gravity: 20,
      decay: 1,
      startVelocity: 25,
      count: ruleList[rule_id]?.order,
      scalar: 5,
      shapes: ['image'],
      shapeOptions: {
        image: [
          {
            src: ruleList[rule_id]?.type,
            width: 32,
            height: 32,
          },
        ],
      },
      flat: true,
    });
  };

  return { run };
};

export default useSpinResultAnimate;
