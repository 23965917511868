/*
 * Created Date: August 27th 2024, 11:13:31 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 29th 2024, 11:31:45 am
 */

import { useCallback, useEffect, useRef } from 'react';
/**
 * Check Typescript section
 * and use your path to adsgram types
 */

export interface ShowPromiseResult {
  done: boolean;
  description: string;
  state: 'load' | 'render' | 'playing' | 'destroy';
  error: boolean;
}

type EventType =
  | 'onReward'
  | 'onStart'
  | 'onSkip'
  | 'onBannerNotFound'
  | 'onError';
type HandlerType = () => void;

export interface AdController {
  show(): Promise<ShowPromiseResult>;
  addEventListener(event: EventType, handler: HandlerType): void;
  removeEventListener(event: EventType, handler: HandlerType): void;
  destroy(): void;
}

export interface useAdsgramParams {
  onReward: (nanoid: string) => void;
  onError?: (result: ShowPromiseResult) => void;
}

const hostName = window.location.hostname;

const blockIDMap: { [key: string]: string } = {
  'miniapp.tabibot.com': '2466',
  'front.tabibot.com': '4600',
  'app.tabibot.com': '5136',
};

export function useAdsgram({
  onReward,
  onError,
}: useAdsgramParams): (nanoid: string) => Promise<void> {
  const AdControllerRef = useRef<AdController | undefined>(undefined);

  useEffect(() => {
    const debugOptions = import.meta.env.DEV
      ? { debug: true, debugBannerType: 'FullscreenMedia' }
      : {};
    AdControllerRef.current = (window as any).Adsgram?.init({
      blockId: blockIDMap[hostName] ?? import.meta.env.VITE_BLOCK_ID,
      ...debugOptions,
    });
  }, []);

  return useCallback(
    async (nanoid: string) => {
      if (AdControllerRef.current) {
        try {
          await AdControllerRef.current.show();
          onReward(nanoid);
        } catch (err: any) {
          onError?.(err);
        }

        return;
      }

      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    },
    [onError, onReward]
  );
}
