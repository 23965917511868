import './mockEnv';

import App from '@/App.tsx';
import ReactDOM from 'react-dom/client';

import '@/styles/index.css';
import 'animate.css';
import '@telegram-apps/telegram-ui/dist/styles.css';

import '@/utils/sentry';

import '@/utils/vconsole';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);
