import lazyWithPreload from '@/utils/lazy';
import { Suspense } from 'react';

const preloadPage = (
  path: () => Promise<{
    default: React.ComponentType<any>;
  }>
) => {
  const Component = lazyWithPreload(path);
  Component.preload();
  return Component;
};

// 集中管理路由配置
const lazyRouters = [
  {
    path: '/inviteRecord',
    component: preloadPage(() => import('@/pages/InviteRecord')),
  },
  {
    path: '/inviteRules',
    component: preloadPage(() => import('@/pages/InviteRules')),
  },
  {
    path: '/tonOverview',
    component: preloadPage(() => import('@/pages/TonOverview')),
  },
  {
    path: '/tonRecord',
    component: preloadPage(() => import('@/pages/TonRecord')),
  },
  {
    path: '/tonCashOut',
    component: preloadPage(() => import('@/pages/TonCashOut')),
  },
  {
    path: '/leaderboard',
    component: preloadPage(() => import('@/pages/Leaderboard')),
  },
  {
    path: '/upgrade',
    component: preloadPage(() => import('@/pages/Upgrade')),
  },
  {
    path: '/mine/project/:projectTag',
    component: preloadPage(() => import('@/pages/Mine/ProjectTask')),
  },
  {
    path: '/profile',
    component: preloadPage(() => import('@/pages/Profile')),
  },
];

export default (() => {
  const routers = lazyRouters.map(({ path, component: Component }) => ({
    path,
    element: (
      <Suspense>
        <Component />
      </Suspense>
    ),
  }));
  return routers;
})();
