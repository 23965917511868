import { Await, NavigateFunction, Outlet, useNavigate } from 'react-router-dom';
import { Suspense, useMemo } from 'react';
import PageLoading from '@/components/PageLoading';
import {
  getLevelConfig,
  getSysStatus,
  getUserProfile,
  signin,
  SysStatusEnum,
} from '@/api/game';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { LoadingProvider } from '@/components/LoadingProvider';

const loader = async (navigate: NavigateFunction) => {
  const url = window.location.pathname;
  const { startParam, initData } = retrieveLaunchParams();
  const signInParams = {
    referral:
      startParam && startParam?.toString ? Number(startParam?.toString()) : 0,
  };

  const results = await Promise.allSettled([
    getSysStatus(),
    signin(signInParams),
  ]);

  const sysStatus = results[0].status === 'fulfilled' ? results[0].value : null;
  if (
    sysStatus?.status === SysStatusEnum.Open &&
    !url.includes('/upgrading') &&
    !sysStatus?.list?.includes(Number(initData?.user?.id))
  ) {
    navigate(`/upgrading?msg=${encodeURI(sysStatus?.msg)}`, { replace: true });
    return;
  }

  const payload = await Promise.allSettled([
    getLevelConfig(),
    getUserProfile(),
  ]);

  const levelConfigs =
    payload[0].status === 'fulfilled' ? payload[0].value : undefined;
  const userProfile =
    payload[1].status === 'fulfilled' ? payload[1].value : undefined;

  if (url === '/') {
    navigate('/index', { replace: true });
  }

  return {
    userProfile,

    levelConfigs,
  };
};

const BasicLayout = () => {
  const navigate = useNavigate();
  const loaderCache = useMemo(() => loader(navigate), []);
  return (
    <LoadingProvider>
      <div className="overflow-y-auto h-screen">
        <Suspense fallback={<PageLoading />}>
          <Await resolve={loaderCache}>
            <Outlet />
          </Await>
        </Suspense>
      </div>
    </LoadingProvider>
  );
};

export default BasicLayout;
