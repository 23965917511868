import { useLocalStorageState } from 'ahooks';
import { FC } from 'react';
import { Range } from 'react-range';

export enum VolumeType {
  Sound = 'sound',
}

const SoundVolume: FC = () => {
  const [value, setValue] = useLocalStorageState(VolumeType.Sound, {
    defaultValue: 100,
  });
  return (
    <Range
      step={1}
      min={0}
      max={100}
      values={[value ?? 100]}
      onChange={(values) => setValue(values[0])}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          className="w-[177px] h-[8px] bg-[#ffffff] rounded-[7px]"
          style={props.style}
        >
          <div
            className="absolute h-full bg-[#FF6121] cursor-pointer rounded-[7px] [box-shadow:0px_-1.6px_0px_0px_inset_#DB4E14]"
            style={{
              width: `calc(${value ?? 100}%)`,
            }}
          ></div>
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...{ ...props, tabIndex: undefined }}
          key={props.key}
          className="w-[18px] h-[18px] cursor-move bg-[#FF6121]  rounded-full [border:2px_solid_#ffffff]"
        />
      )}
    />
  );
};
export default SoundVolume;
