import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from '@/const/images';

const BeforeLevel3Process: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-[315px] flex justify-between items-center shadow-[0px_-3px_0px_0px_#c6dbcd_inset] absolute box-border px-[15px] pb-[20px] pt-[16px] rounded-lg bottom-[20vh] bg-white leading-[20px]">
      <div className="flex flex-col font-changa-one ">
        <div className="flex items-center gap-x-[4px]">
          <img
            className="w-[20px] h-[20px]"
            src={images.tabbar.spinSelectedIcon}
            width={20}
            height={20}
          />
          <label>Spin to earn</label>
        </div>
        <div className="w-[205px] font-poppins text-[10px] text-[rgba(0,0,0,0.3)] text-start mt-[4px] leading-[14px]">
          You can only get the ability to Mining{' '}
          <span className="text-[rgba(255,92,1,1)]">$Zoo</span> after level 3.
        </div>
      </div>
      <div
        className="w-[66px] h-[26px] border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-2px_0px_0px_rgba(0,0,0,0.15)_inset] text-white rounded-lg border-solid border-[rgba(255,255,255,0)] bg-[#ff6121] flex justify-center items-center"
        onClick={() => navigate('/spin')}
      >
        <span className="mt-[-2px] text-[12px] font-changa-one">Go</span>
      </div>
    </div>
  );
};

export default React.memo(BeforeLevel3Process);
