import images from '@/const/images';
import React, { FC, memo } from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;
const ImageWithDefault: FC<Props> = ({ src, onError, ...rest }) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (onError) {
      onError(e);
      return;
    }
    e.currentTarget.src = images.common.defaultImage;
  };

  return <img src={src} {...rest} onError={handleError} />;
};

export default memo(ImageWithDefault);
