import { SubTaskEntity } from '@/api/mine';
import logEvent, { EventName } from '@/utils/firebase';

const TaskLogEvent = (task: SubTaskEntity) => {
  if (task.task_tag === 'task_daily_check_in') {
    logEvent(EventName.SIGNIN_NORMAL_CLICK, {
      value: 'daily',
    });
  }

  if (task.task_tag === 'task_list_watch_ads') {
    logEvent(EventName.SIGNIN_ADVERTISE_CLICK, {
      value: 'advertise',
    });
  }

  if (task.project_tag === 'task_daily_reward') {
    logEvent(EventName.TASK_CONTENT_CLICK, {
      click_name: 'daily task',
      task_name: `${task.task_name}`,
    });
    return;
  }

  if (task.project_tag === 'task_list') {
    logEvent(EventName.TASK_CONTENT_CLICK, {
      click_name: 'task list',
      task_name: `${task.task_name}`,
    });
    return;
  }
};

export default TaskLogEvent;
