const spinIcons = [
  ['rect1', 'mid1', 'right1'],
  ['rect2', 'mid2', 'right2'],
  ['rect3', 'mid3', 'right3'],
  ['rect4', 'mid4', 'right4'],
  ['rect5', 'mid5', 'right5'],
];

export const getDynamicUrls = (col: number) => {
  let result: string[] = [];
  for (let i = 0; i < spinIcons.length; i++) {
    const url = new URL(
      `../../assets/images/game/spin/${spinIcons[i][col]}.png`,
      import.meta.url
    );
    result.push(`url(${url.pathname}) center / cover no-repeat`);
  }
  return result;
};

export enum SpinIconType {
  BEER,
  CHEST,
  COIN,
  CRYSTAL,
  ENERGY,
  SHIRO,
  ZOO,
}

enum ShowSpinIconType {
  CONTINUOUS,
  NON_CONTINUOUS,
  EXCLUDE_SELF_RANDOM,
  RANDOM,
}

enum Emote {
  SHIRO_EMOTE, // 1.9s
  TRIPLET, // 1.6s
  TRIPLET_ENERGY, // 1.6s
  TRIPLET_BEER, // 2s
  LESS_COIN, // 2s
  MORE_COIN, // 2s
}

interface IEmoteTime {
  [key: number]: {
    time: number;
  };
}

const emoteTime: IEmoteTime = {
  [Emote.SHIRO_EMOTE]: { time: 1900 },
  [Emote.TRIPLET]: { time: 1900 },
  [Emote.TRIPLET_ENERGY]: { time: 1600 },
  [Emote.TRIPLET_BEER]: { time: 2000 },
  [Emote.LESS_COIN]: { time: 2000 },
  [Emote.MORE_COIN]: { time: 2000 },
};

interface IEmoteRule {
  index: number;
  emoteIndex: Emote;
}

interface ISpinIconsRule {
  [key: number]: {
    spinIcons: [number, number, number];
  } & IEmoteRule;
}

interface IShowAndExcludeRule {
  [key: number]: {
    showSpinIcon: SpinIconType;
    exclude: SpinIconType | SpinIconType[];
    showType: ShowSpinIconType;
  } & IEmoteRule;
}

const excludedRule: IShowAndExcludeRule = {
  3: {
    showSpinIcon: SpinIconType.CRYSTAL,
    exclude: SpinIconType.CRYSTAL,
    showType: ShowSpinIconType.CONTINUOUS,
    index: 3,
    emoteIndex: Emote.MORE_COIN,
  },
  4: {
    showSpinIcon: SpinIconType.CRYSTAL,
    exclude: SpinIconType.CRYSTAL,
    showType: ShowSpinIconType.NON_CONTINUOUS,
    index: 3,
    emoteIndex: Emote.MORE_COIN,
  },
  6: {
    showSpinIcon: SpinIconType.ZOO,
    exclude: SpinIconType.ZOO,
    showType: ShowSpinIconType.CONTINUOUS,
    index: 6,
    emoteIndex: Emote.MORE_COIN,
  },
  7: {
    showSpinIcon: SpinIconType.ZOO,
    exclude: SpinIconType.ZOO,
    showType: ShowSpinIconType.NON_CONTINUOUS,
    index: 6,
    emoteIndex: Emote.MORE_COIN,
  },
  12: {
    showSpinIcon: SpinIconType.CHEST,
    exclude: [SpinIconType.COIN, SpinIconType.CHEST],
    showType: ShowSpinIconType.EXCLUDE_SELF_RANDOM,
    index: 2,
    emoteIndex: Emote.MORE_COIN,
  },
  13: {
    showSpinIcon: SpinIconType.CHEST,
    exclude: [SpinIconType.COIN, SpinIconType.CHEST],
    showType: ShowSpinIconType.RANDOM,
    index: 2,
    emoteIndex: Emote.MORE_COIN,
  },
  14: {
    showSpinIcon: SpinIconType.COIN,
    exclude: [SpinIconType.COIN, SpinIconType.CHEST],
    showType: ShowSpinIconType.EXCLUDE_SELF_RANDOM,
    index: 2,
    emoteIndex: Emote.LESS_COIN,
  },
  15: {
    showSpinIcon: SpinIconType.COIN,
    exclude: [SpinIconType.COIN, SpinIconType.CHEST],
    showType: ShowSpinIconType.RANDOM,
    index: 2,
    emoteIndex: Emote.LESS_COIN,
  },
};

const staticSpinIcons: ISpinIconsRule = {
  1: {
    spinIcons: [SpinIconType.ENERGY, SpinIconType.ENERGY, SpinIconType.ENERGY],
    index: 4,
    emoteIndex: Emote.TRIPLET_ENERGY,
  },
  2: {
    spinIcons: [
      SpinIconType.CRYSTAL,
      SpinIconType.CRYSTAL,
      SpinIconType.CRYSTAL,
    ],
    index: 3,
    emoteIndex: Emote.TRIPLET,
  },
  5: {
    spinIcons: [SpinIconType.ZOO, SpinIconType.ZOO, SpinIconType.ZOO],
    index: 6,
    emoteIndex: Emote.TRIPLET,
  },
  8: {
    spinIcons: [SpinIconType.SHIRO, SpinIconType.SHIRO, SpinIconType.SHIRO],
    index: 2,
    emoteIndex: Emote.SHIRO_EMOTE,
  },
  9: {
    spinIcons: [SpinIconType.CHEST, SpinIconType.CHEST, SpinIconType.CHEST],
    index: 2,
    emoteIndex: Emote.MORE_COIN,
  },
  10: {
    spinIcons: [SpinIconType.COIN, SpinIconType.COIN, SpinIconType.COIN],
    index: 2,
    emoteIndex: Emote.MORE_COIN,
  },
  11: {
    spinIcons: [SpinIconType.BEER, SpinIconType.BEER, SpinIconType.BEER],
    index: 2,
    emoteIndex: Emote.TRIPLET_BEER,
  },
};

const getRandomExcludingNumber = (
  excludedNumber: number | number[],
  random = 6
): number => {
  let randomNumber;
  while (true) {
    randomNumber = Math.floor(Math.random() * random);
    if (Array.isArray(excludedNumber)) {
      if (!excludedNumber.includes(randomNumber)) break;
    } else {
      if (randomNumber !== excludedNumber) break;
    }
  }
  return randomNumber;
};

const getCurrentSpinIconRandomIndex = () => {
  return Math.floor(Math.random() * 2);
};

const dynamicSpinIcons = (ruleId: number) => {
  let result = [0, 0, 0];
  let i = 0;
  let emote = 0;
  if (excludedRule[ruleId]) {
    const { showSpinIcon, exclude, showType, index, emoteIndex } =
      excludedRule[ruleId];
    i = index;
    emote = emoteIndex;
    switch (showType) {
      case ShowSpinIconType.CONTINUOUS:
        result = [
          showSpinIcon,
          showSpinIcon,
          getRandomExcludingNumber(exclude),
        ];
        break;
      case ShowSpinIconType.NON_CONTINUOUS:
        result = [
          showSpinIcon,
          getRandomExcludingNumber(exclude),
          showSpinIcon,
        ];
        break;
      case ShowSpinIconType.EXCLUDE_SELF_RANDOM:
        const currentIndex = getCurrentSpinIconRandomIndex();
        const nextIndex = getRandomExcludingNumber(currentIndex, 2);
        result = result.map((_, i) => {
          if (i === currentIndex || i === nextIndex) {
            return showSpinIcon;
          }
          return getRandomExcludingNumber(exclude);
        });
        break;
      default:
        const index = getCurrentSpinIconRandomIndex();
        let currentShowIcon: number | undefined = undefined;
        result = result.map((_, i) => {
          if (i === index) {
            return showSpinIcon;
          }
          if (currentShowIcon !== undefined) {
            return getRandomExcludingNumber([
              ...(exclude as SpinIconType[]),
              currentShowIcon,
            ]);
          } else {
            currentShowIcon = getRandomExcludingNumber(exclude);
            return currentShowIcon;
          }
        });

        break;
    }
  } else {
    result = staticSpinIcons[ruleId].spinIcons;
    i = staticSpinIcons[ruleId].index;
    emote = staticSpinIcons[ruleId].emoteIndex;
  }
  return {
    result,
    i,
    emote,
    time: emoteTime[emote].time,
  };
};

export default dynamicSpinIcons;
