import request from '.';

export interface ITaskRecord {
  id: number;
  task_tag: string;
  project_tag: string;
  task_name: string;
  task_order: number;
  link_url: string;
  img_url: string;
  reward: number;
  finish_type: string;
  task_type: string;
  ext: string;
  task_detail_title: string;
  task_detail_desc: string;
  insert_tm: string;
  update_tm: string;
  user_task_status: number;
}

export enum TaskStatus {
  Done = 1,
  Todo = 2,
}

export interface IVerifyTask {
  reward: number;
  verify: boolean;
  status: number;
}

export const verifyOnboarding = async () => {
  const res = await request('post', 'task/v1/onboarding');
  return res;
};

export const getTaskRecord = async () => {
  const res: ITaskRecord[] = await request('get', 'task/v1/boot');
  return res;
};

export const verifyTask = async (params: { task_tag: string }) => {
  const res: IVerifyTask = await request('post', 'task/v1/verify/task', {
    json: params,
  });
  return res;
};

