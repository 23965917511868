import { useRef } from 'react';
import images from '@/const/images';
import clsx from 'clsx';

import Modal, { ModalRef } from '../../Modal';
import Toast, { ToastRef } from '../../Toast';

/*
 * Created Date: August 11th 2024, 10:49:52 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 29th 2024, 11:31:45 am
 */

export default function ConfirmModal({
  reward,
  modalRef,
}: {
  reward: string;
  modalRef: React.MutableRefObject<ModalRef | undefined>;
}) {
  const toastRef = useRef<ToastRef>();

  return (
    <Modal
      ref={modalRef}
      iconChildren={
        <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[262px]">
          <img
            className="w-full"
            src={images.mine.mineClaimSuccessIcon}
            width={100}
            height={100}
          />

          <img
            src={images.mine.mineClaimIcon}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120px]"
          />
        </div>
      }
    >
      <div
        className={clsx(
          'w-[339px] pb-[20px] shrink-0 shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center',
          '[background:linear-gradient(180deg,rgba(255,62,1,0.16)_0%,rgba(255,255,255,0.00)_100%),#FFF]'
        )}
      >
        <h3 className="text-xl font-changa-one text-black">
          Congratulations !
        </h3>
        <p className="font-poppins text-black text-sm mt-[6px] line-clamp-3 leading-[14px] opacity-30">
          you've received
        </p>
        <div
          className={clsx(
            'px-[29px] h-[54px] rounded-[10px] border-[1.4px] border-[#E5E3EE] text-[#f75f21] text-xl font-changa-one capitalize leading-[14px] flex items-center justify-center mt-[25px] mx-auto [box-shadow:0px_3px_0px_0px_#E5E3EE_inset] w-fit',
            'bg-black/[0.04]'
          )}
        >
          <img
            src={images.common.coin}
            className="w-[28px] h-[28px] mt-[5px]"
            width={24}
            height={24}
          />
          +{reward || 0}
        </div>
        <div
          className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[135px] py-3.5 rounded-[47px] mx-auto text-white mt-[25px] text-center font-changa-one text-base leading-[14px] capitalize"
          onClick={() => {
            modalRef.current?.hide();
          }}
        >
          Confirm
        </div>
      </div>

      <Toast ref={toastRef}></Toast>
    </Modal>
  );
}
