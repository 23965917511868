/*
 * Created Date: August 9th 2024, 5:14:33 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 28th 2024, 6:25:01 pm
 */
import request, { api, BaseResponse } from '.';

export enum Status {
  Success = 1,
  NotFinish = 2,
}

export interface Project {
  id: number;
  project_tag: string;
  project_name: string;
  project_order: number;
  img_url: string;
  reward: number;
  time_limit: number;
  begin_time: string;
  end_time: string;
  total: number;
  claimed_num: number;
  head_img_url: string;
  desc: string;
  click_type: string;
  click_link_url: string;
  insert_tm: string;
  update_tm: string;
  user_project_status: Status;
}

export const getRewardProjects = async () => {
  const res = await request<Array<Project>>('get', 'task/v1/project/mine');

  return res || [];
};

export enum RedirectType {
  Web = 'web',
  Project = 'project',
  Internal = 'internal',
}

export interface Banner {
  id: number;
  title: string;
  description: string;
  image_url: string;
  redirect_url: string;
  redirect_type: RedirectType;
}

export const getBanners = async () => {
  const res = await request<Banner[]>('get', 'task/v1/mine/banners');

  return res;
};

export interface ProjectDetail {
  head_img_url: string;
  project_name: string;
  desc: string;
  reward: number;
  list: SubTaskEntity[];
  user_project_status: Status;
}

export enum TaskType {
  JoinTgChannel = 'join_tg_channel',
  BindTabiAddress = 'bind_tabi_address',
  ConnectTonWallet = 'connect_ton_wallet',
  JoinTgGroup = 'join_tg_group',
  WebPass = 'web_pass',
  FrontCheckIn = 'front_check_in',
  Invite3Person = 'invite_3_person',
  PostStory = 'post_story',
  WatchAds = 'repeat_watch_ads',
  Carnival = 'carnival',
  LaunchOKX = 'launch_wallet',
  OpenChannel = 'open_channel',
  JumpProject = 'jump_project',
}

export interface SubTaskEntity {
  id: number;
  task_tag: string;
  project_tag: string;
  task_name: string;
  task_order: number;
  link_url: string;
  img_url: string;
  reward: number;
  finish_type: string;
  task_type: TaskType;
  ext: string;
  task_detail_title: string;
  task_detail_desc: string;
  insert_tm: string;
  update_tm: string;
  user_task_status: Status;
  watch_ads_cold_time?: string;
  watch_ads_count?: number;
}

export const getProjectDetail = async (project_tag: string) => {
  const res = await request<ProjectDetail>('get', `task/v1/mine`, {
    searchParams: { project_tag },
  });

  return res;
};

export enum ClaimStatus {
  Failed = 0,
  Success = 1,
  Ended = 2,
}

export interface ClaimResult {
  reward: number;
  verify: boolean;
  status: ClaimStatus;
}

export const claim = async (projectTag: string) => {
  const res = await api
    .post(`task/v1/verify/project`, {
      json: {
        project_tag: projectTag,
      },
    })
    .json<BaseResponse<ClaimResult>>();

  return res;
};

export const uploadCount = async (taskTag: string) => {
  try {
    await api.post('task/v1/report/go', {
      json: { task_tag: taskTag },
    });
  } catch (err) {}
};
