import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React from 'react';

type Props = {
  extendsAModal: React.MutableRefObject<ModalRef | undefined>;
  onHide: () => void;
};

const ExtendsAModal: React.FC<Props> = ({ extendsAModal, onHide }) => {
  return (
    <Modal
      ref={extendsAModal}
      onClose={() => {
        onHide();
        return true;
      }}
      iconChildren={
        <img
          className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[132px]"
          src={images.game.upgradeA}
        />
      }
    >
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(183.51deg,_rgba(255,_62,_1,_0.16)_2.89%,_rgba(255,_255,_255,_0)_56.84%),_#FFFFFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] mt-[8px] font-changa-one text-black">
          System Upgrade Instructions
        </h3>
        <p className="font-rubik text-black opacity-50 text-[14px] mt-[8px] w-[260px] mx-auto text-left">
          Thank you for taking such great care of Shiro in TabiZoo 1.0!
        </p>
        <p className="font-rubik text-black opacity-50 text-[14px] mt-[8px] w-[260px] mx-auto text-left">
          Your Shiro has evolved, gaining enhanced intelligence and unlocking
          the ability to mine <span className="font-bold">$Zoo.</span>
        </p>
        <p className="font-rubik text-black opacity-50 text-[12px] mt-[8px] w-[260px] mx-auto text-left">
          We're reviewing the levels and coins you’ve accumulated since the
          launch and will soon reward our loyal users with some shiny $Zoo. Get
          ready for the surprise!
        </p>
        <div
          onClick={onHide}
          className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[15px] text-nowrap font-bold font-changa-one"
        >
          View Rewards
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ExtendsAModal);
