import { SwitchType } from '@/components/SwitchButton';
import { AnyHapticFeedbackParams, postEvent } from '@telegram-apps/sdk-react';
import store from 'store';

const useTriggerHaptic = () => {
  const triggerHaptic = (params: AnyHapticFeedbackParams) => {
    if (store.get(SwitchType.Vibrate)) {
      postEvent('web_app_trigger_haptic_feedback', params);
    }
  };

  return {
    triggerHaptic,
  };
};

export default useTriggerHaptic;
