import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { useMemo } from 'react';

const excludedApp = ['macos', 'tdesktop', 'web'];
const useIsApp = () => {
  const { platform } = retrieveLaunchParams();

  return useMemo(() => !excludedApp.includes(platform), [platform]);
};

export default useIsApp;
