import { useMemo, useState } from 'react';
import { useCountDown } from 'ahooks';
import dayjs from '@/utils/dayjs';
import useProfile from './useProfile';

export default function useIsSpin() {
  const { userProfile, run } = useProfile();

  const [targetDate, setTargetDate] = useState<number>();

  useCountDown({
    targetDate,
    onEnd() {
      run();
    },
  });

  const isSpin = useMemo(() => {
    if (!userProfile) return false;
    if (userProfile?.user?.energy?.energy > 0) {
      return true;
    } else {
      setTargetDate(
        dayjs.utc(userProfile?.user?.energy?.energy_next_updated).valueOf()
      );
      return false;
    }
  }, [userProfile?.user?.energy]);

  return { isSpin };
}
