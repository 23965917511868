export enum EventName {
  GLOBAL_MAIN_TAB_CLICK = 'global_main_tab_click',
  INVITE_INVITE_CLICK = 'invite_invite_click',
  SHIRO_BUTTON_CLICK = 'shiro_button_click',
  SHIRO_UPGRADE_CLICK = 'shiro_upgrade_click',
  INVITE_RULE_CLICK = 'invite_rule_click',
  SLOT_BUTTON_CLICK = 'slot_button_click',
  SIGNIN_NORMAL_CLICK = 'Signin_normal_click',
  SIGNIN_ADVERTISE_CLICK = 'Signin_advertise_click',
  TASK_CONTENT_CLICK = 'task_content_click',
  TASK_POP_CLICK = 'task_pop_click',
  EXCHANGE_BANNER_CLICK = 'exchange_banner_click',
  EXCHANGE_VIEW_CLICK = 'exchange_view_click',
}
const logEvent = (event: EventName, params?: Record<string, string>) => {
  gtag('event', event, {
    ...params,
    event_category: 'User Interaction',
    event_label: 'Button Clicked',
  });
};

export default logEvent;
