import React from 'react';
import { useLocalStorageState, useMount } from 'ahooks';
import clsx from 'clsx';
import store from 'store';

export enum SwitchType {
  Vibrate = 'vibrate',
}
const SwitchButton: React.FC = () => {
  const [value, setValue] = useLocalStorageState(SwitchType.Vibrate, {
    defaultValue: true,
  });

  useMount(() => {
    if (store.get(SwitchType.Vibrate) === undefined) {
      setValue(true);
    }
  });
  const handleClickToggle = () => {
    setValue(!value);
  };
  return (
    <div className="relative w-[44px] h-[24px] cursor-pointer" onClick={handleClickToggle}>
      <span
        className={clsx(
          'absolute w-[44px] h-[24px] cursor-pointer top-0 left-0 right-0 bottom-0 duration-[0.4s] [border:2px_solid_#FDFCFF] rounded-[12px] [box-shadow:0px_4px_4px_0px_#92876A4D_inset]',
          value ? 'bg-[#F95F20]' : 'bg-[#E7E6EB]'
        )}
      ></span>
      <span
        className={clsx(
          'absolute h-[18px] w-[18px] left-[3px] top-[3px] bg-[#FFFFFF] [box-shadow:0px_1.6px_0px_0px_#00000033] duration-[0.4s] rounded-full',
          value && 'translate-x-[20px]'
        )}
      ></span>
    </div>
  );
};

export default React.memo(SwitchButton);
