import { useSearchParams } from 'react-router-dom';

import images from '@/const/images';
import Loading from '@/components/Loading';

const Upgrading = () => {
  const [searchParams] = useSearchParams();

  return (
    <div
      className="fixed w-screen h-screen z-[105] flex justify-center items-center left-0 top-0 bg-white bg-cover bg-center"
      style={{ backgroundImage: `url(${images.pageLoading.bg})` }}
    >
      <div className="absolute bottom-[70vw] text-center z-8">
        <div className="w-[205px] h-[72px] bg-[url('@/assets/images/pageLoading/title.png')] bg-contain pt-[45px] mx-auto mb-[10px]">
          <span className="pageLoading-text text-[16px] font-changa-one">
            {import.meta.env?.VITE_VERSION}
          </span>
        </div>
      </div>

      <img
        className="absolute bottom-[65vw] right-0 w-[60px] z-9"
        src={images.pageLoading.coin}
      />

      <img
        className="absolute bottom-0 right-0 w-screen z-9"
        src={images.pageLoading.flowers}
      />

      <div className="absolute text-[20px] text-center text-white bottom-[60vw] font-changa-one flex items-center gap-[5px]">
        {searchParams.get('msg')}
        <Loading />
      </div>
    </div>
  );
};

export default Upgrading;
