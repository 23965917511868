import { ILevelConfig } from '@/api/game';

const LEVEL_COUNT = 6;

export const getUpgradeInfo = (
  value: ILevelConfig | undefined,
  nextValue: ILevelConfig | undefined
) => {
  if (!value) return undefined;
  const nextLevel = nextValue || value;
  return {
    currentLevel: `${value.major_level}`,
    currentSecondaryLevel: value.secondary_level,
    nextSecondaryLevel: nextLevel.secondary_level,
    nextLevel: `${nextLevel.major_level}`,
    currentMiningSpeed: value.zoo_rate,
    nextMiningSpeed: nextLevel.zoo_rate,
    consume: value.level_up_coin,
  };
};

export const getLevelCount = (value: ILevelConfig | undefined) => {
  let temp: boolean[] = [];

  if (value) {
    for (let i = 0; i < LEVEL_COUNT; i++) {
      if (i < value.secondary_level) {
        temp.push(true);
      } else {
        temp.push(false);
      }
    }
  }

  return temp;
};
