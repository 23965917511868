/*
 * Created Date: August 10th 2024, 7:58:49 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 10th 2024, 7:58:49 pm
 */
import './index.css';
export default function Loading() {
  return <div className="loader"></div>;
}
