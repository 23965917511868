import { getLevelConfig, IUserProfile } from '@/api/game';
import { IRouterRootLoaderData } from '@/router';
import { useMount, useRequest } from 'ahooks';
import { useMemo } from 'react';
import { useAsyncValue } from 'react-router-dom';

const useLevelConfig = (userProfile: IUserProfile | undefined) => {
  const loaderData = useAsyncValue() as IRouterRootLoaderData;

  const { data, run } = useRequest(getLevelConfig, {
    manual: true,
    cacheKey: 'levelConfigs',
  });

  useMount(() => {
    if (!loaderData?.levelConfigs) {
      run();
    }
  });

  const values = useMemo(() => {
    if (loaderData && loaderData.levelConfigs) return loaderData?.levelConfigs;

    return data;
  }, [loaderData?.levelConfigs, data]);

  const { preLevel, level, nextLevel, isFinalLevel } = useMemo(() => {
    const length = values?.user?.length || 0;
    let preLevel = undefined;
    let level = undefined;
    let nextLevel = undefined;
    let isFinalLevel = false;
    if (userProfile && values) {
      const index = values.user.findIndex(
        (item) =>
          item.major_level === userProfile.user.level &&
          item.secondary_level === userProfile.user.secondary_level
      );
      preLevel = values.user[index - 1] || level;
      level = values.user[index];
      nextLevel = values.user[index + 1] || level;
      isFinalLevel = index + 1 === length;
    }

    return {
      preLevel,
      level,
      nextLevel,
      isFinalLevel,
    };
  }, [userProfile, values]);

  return {
    preLevel,
    level,
    nextLevel,
    isFinalLevel,
  };
};

export default useLevelConfig;
