/*
 * Created Date: September 1st 2024, 11:38:58 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: October 23rd 2024, 5:07:59 pm
 */
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://860f5c2d3ddadea0dafc5580a19eff54@o4508014316617728.ingest.us.sentry.io/4508171243945984',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
