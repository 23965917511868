import router from '@/router/index';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { useMount } from 'ahooks';
import { postEvent } from '@telegram-apps/sdk-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { RouterProvider } from 'react-router-dom';
import PageLoading from './components/PageLoading';

export default function App() {
  useMount(() => {
    postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false });
    postEvent('web_app_expand');
  });

  return (
    <TonConnectUIProvider
      actionsConfiguration={{
        twaReturnUrl: import.meta.env.VITE_TWA_RETURN_URL,
      }}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: 'tonwallet',
            name: 'TON Wallet',
            imageUrl: 'https://wallet.ton.org/assets/ui/qr-logo.png',
            aboutUrl:
              'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
            universalLink: 'https://wallet.ton.org/ton-connect',
            jsBridgeKey: 'tonwallet',
            bridgeUrl: 'https://bridge.tonapi.io/bridge',
            platforms: ['chrome', 'safari', 'ios', 'android'],
          },
        ],
      }}
      manifestUrl={import.meta.env.VITE_TON_MANIFEST_URL}
    >
      <SDKProvider acceptCustomStyles>
        <AppRoot>
          <RouterProvider router={router} fallbackElement={<PageLoading />} />
        </AppRoot>
      </SDKProvider>
    </TonConnectUIProvider>
  );
}
