import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import images from '@/const/images';
import clsx from 'clsx';

export interface SpinToastRef {
  show: (mutil: number) => void;
}

const SpinToast = forwardRef((_, ref) => {
  const [visible, setVisible] = useState(false);
  const [mutil, setMutil] = useState<number>(1);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useImperativeHandle(ref, () => {
    const show = (mutil: number) => {
      setVisible(true);
      setMutil(mutil);
      if (timeoutId.current) clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        setVisible(false);
      }, 2000);
    };

    return { show };
  }, []);

  return (
    <div
      className={clsx(
        visible
          ? 'fixed left-[50%] translate-x-[-50%] top-1/2 -translate-y-1/2 z-[999999] mx-auto inline-flex p-[12px] justify-center items-center'
          : 'hidden'
      )}
    >
      <div className="relative w-[100px] h-[92px] flex flex-col items-center">
        <img
          src={images.game.spin.rewardsTop}
          alt=""
          className="z-[2] w-[70px] h-[42px]"
        />
        <img
          src={images.game.spin.rewardsRect1}
          alt=""
          className="absolute top-[24%] left-0 w-full h-[66px] "
        />
        <div className='absolute flex flex-col items-center justify-center left-0 z-[1] bottom-0 w-full h-[59px] bg-[url("@/assets/images/game/spin/rewards_rect2.png")] bg-cover bg-no-repeat bg-center'>
          <h1 className="font-changa-one text-[#FFB91C] text-[24px]">
            x{mutil}
          </h1>
          <h3 className="font-changa-one text-[#FFFFFF] text-[12px]">
            All Rewards
          </h3>
        </div>
      </div>
    </div>
  );
});

export default SpinToast;
