import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  checkIn,
  checkInAD,
  CheckInStatus,
  reportAD,
  ReportStatus,
} from '@/api/game';
import images from '@/const/images';
import dayjs from '@/utils/dayjs';
import { useRequest } from 'ahooks';
import Loading from '@/components/Loading';
import Modal, { ModalRef } from '@/components/Modal';
import Toast, { ToastRef } from '@/components/Toast';
import { useAdsgram } from '@/hooks/useAdsgram';
import { nanoid } from 'nanoid';
import ConfirmModal from './ConfirmModal';
import useProfile from '@/hooks/useProfile';

export interface CheckInModalRef {
  show: () => void;
  hide: () => void;
}

const CheckInModal = forwardRef(
  (
    {
      refresh,
    }: {
      refresh: () => void;
    },
    ref
  ) => {
    const { userProfile, run } = useProfile();

    const isToday = useMemo(
      () =>
        dayjs.utc().format('YYYY-MM-DD') === userProfile?.user?.check_in_date,
      [userProfile?.user?.check_in_date]
    );

    const rewardList = useMemo(
      () => [
        {
          title: 'DAY 1',
          img: images.common.coin,
          coin: '2.5K',
          doubleCoin: '5K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 1,
        },
        {
          title: 'DAY 2',
          img: images.common.coin,
          coin: '5K',
          doubleCoin: '10K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 2,
        },
        {
          title: 'DAY 3',
          img: images.common.coin,
          coin: '12.5K',
          doubleCoin: '25K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 3,
        },
        {
          title: 'DAY 4',
          img: images.common.coin2,
          coin: '25K',
          doubleCoin: '50K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 4,
        },
        {
          title: 'DAY 5',
          img: images.common.coin2,
          coin: '30K',
          doubleCoin: '60K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 5,
        },
        {
          title: 'DAY 6',
          img: images.common.coin2,
          coin: '40K',
          doubleCoin: '80K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 6,
        },
        {
          title: 'DAY 7',
          img: images.common.coin3,
          coin: '50K',
          doubleCoin: '100K',
          isChecked: userProfile?.user.streak && userProfile?.user.streak >= 7,
        },
        {
          title: `DAY ${userProfile?.user.streak ? (userProfile?.user.streak < 8 ? 8 : userProfile.user.streak + 1) : 8}`,
          img: images.common.coin3,
          coin: '50K',
          doubleCoin: '100K',
          isChecked:
            userProfile?.user.streak &&
            userProfile?.user.streak >= 8 &&
            isToday,
        },
      ],
      [userProfile, isToday]
    );

    const checkInModalRef = useRef<ModalRef>();

    const { loading: checkInLoading, run: checkInRun } = useRequest(
      async () => {
        await checkIn();
        run();
        refresh();
      },
      { manual: true }
    );

    useImperativeHandle(ref, () => {
      const show = () => {
        checkInModalRef.current?.show();
      };

      const hide = () => {
        checkInModalRef.current?.hide();
      };

      return { show, hide };
    }, []);

    const firstUnChecked = rewardList.find((item) => !item.isChecked);

    const confirmModalRef = useRef<ModalRef>();

    const [adReward, setADReward] = useState(0);

    const { run: checkAD, loading: checkLoading } = useRequest(
      async (status: CheckInStatus) => {
        const res = await checkInAD(status);

        run();
        refresh();

        if (res.check_in_status === CheckInStatus.Success) {
          confirmModalRef.current?.show();
          setADReward(res.check_in_reward || 0);
          return;
        }

        toastRef.current?.show('error', 'Claim Error!');
      },
      { manual: true }
    );

    const formatAdReward = useMemo(() => {
      if (adReward !== 0) {
        if (adReward < 1000) {
          return adReward + '';
        }

        return `${adReward / 1000}K`;
      }

      return 0 + '';
    }, [adReward]);

    const toastRef = useRef<ToastRef>();

    const onReward = useCallback((id: string) => {
      reportAD({ status: ReportStatus.Done, ad_id: id, from: 'check_in' });

      checkAD(CheckInStatus.Success);

    }, []);

    const onError = useCallback(() => {
      // checkAD(CheckInStatus.Fail);
      toastRef.current?.show('error', 'Claim Error!');
    }, []);

    const showAd = useAdsgram({
      onReward,
      onError,
    });

    const { loading: showAdLoading, run: runShowAd } = useRequest(
      async () => {
        const id = nanoid(5);
        reportAD({ status: ReportStatus.Tap, ad_id: id, from: 'check_in' });

        await showAd(id);
      },
      { manual: true }
    );

    return (
      <>
        <Modal
          ref={checkInModalRef}
          icon={images.game.checkInModalIcon}
          onClose={() => {
            setADReward(0);
          }}
        >
          <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(158,255,1,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
            <h3 className="text-xl font-changa-one text-black">Daily Reward</h3>
            <p className="font-poppins text-[rgba(0,0,0,0.3)] w-[80%] mx-auto leading-5">
              Accrue coin for logging into the game daily without skipping
            </p>

            <div className="flex flex-wrap justify-center mt-4 gap-2">
              {rewardList?.map((reward) => (
                <div
                  key={reward.title}
                  className="w-[69px] h-[69px] shrink-0 rounded border [background:#EDEADA] border-solid border-[#E4E0CD] flex flex-col justify-center items-center relative"
                >
                  {!!reward.isChecked && (
                    <div
                      key={reward.title}
                      className="absolute w-[69px] h-[69px] shrink-0 rounded border bg-[rgba(253,96,25,0.85)] border-solid border-[#E4E0CD] flex flex-col justify-center items-center text-[12px] text-white font-changa-one gap-[3px]"
                    >
                      <img
                        className="w-[21px] mb-[-3px]"
                        src={images.common.checkedIcon}
                        alt=""
                      />
                    </div>
                  )}
                  <h4 className="m-0 p-0 text-[14px] text-[rgba(172,152,134,0.3)] font-changa-one">
                    {reward.title}
                  </h4>
                  <img
                    className="w-[40px] h-[40px] mt-[-12px]"
                    src={reward.img}
                    alt=""
                  />
                  <p className="m-0 p-0 text-[14px] text-[#fd6019] font-changa-one mt-[-12px]">
                    {reward.coin}
                  </p>
                </div>
              ))}
            </div>

            {isToday ? (
              <div className="flex w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#D9D9D9] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-[47px] text-[#8f8f8f] font-changa-one mx-auto mt-6">
                Come Back Tomorrow
              </div>
            ) : (
              <>
                <div
                  className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[117px] py-3.5 rounded-[47px] text-white font-changa-one mx-auto mt-6"
                  onClick={() => {
                    if (checkInLoading) {
                      return;
                    }

                    checkInRun();
                  }}
                >
                  {checkInLoading ? (
                    <Loading />
                  ) : (
                    <div className="flex items-center gap-[8px]">
                      Claim
                      <div className="flex items-center">
                        {firstUnChecked?.coin}
                        <img
                          src={images.common.coin}
                          alt=""
                          width={24}
                          height={24}
                          className="w-[24px] h-[24px] mt-[5px] ml-[2px]"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] py-3.5 rounded-[47px] text-white font-changa-one mx-auto mt-3"
                  onClick={() => {
                    if (showAdLoading || checkLoading) {
                      return;
                    }

                    runShowAd();
                  }}
                >
                  {showAdLoading || checkLoading ? (
                    <Loading />
                  ) : (
                    <div className="flex items-center gap-[8px]">
                      <img
                        src={images.common.tv}
                        width={24}
                        height={24}
                        className="w-[24px] h-[24px]"
                      />
                      ADS{' '}
                      <div className="flex items-center">
                        {firstUnChecked?.doubleCoin}
                        <img
                          src={images.common.coin}
                          alt=""
                          width={24}
                          height={24}
                          className="w-[24px] h-[24px] mt-[5px] ml-[2px]"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <Toast ref={toastRef}></Toast>
        </Modal>
        <ConfirmModal modalRef={confirmModalRef} reward={formatAdReward} />
      </>
    );
  }
);

export default CheckInModal;
