import { createPortal } from 'react-dom';
import './index.css';

const Loading = () => {
  return createPortal(
    <div className="fixed inset-0 flex flex-col items-center justify-center w-screen h-screen shrink-0 [background:rgba(0,0,0,0.20)] backdrop-blur-[5px]">
      <div className="loading"></div>
    </div>,
    document.body
  );
};

export default Loading;
