/*
 * Created Date: August 9th 2024, 5:25:31 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 9th 2024, 5:25:31 pm
 */
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(utc);

export default dayjs;
