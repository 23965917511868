import { getUserProfile } from '@/api/game';
import { IRouterRootLoaderData } from '@/router';
import { useMount, useRequest } from 'ahooks';
import { useMemo } from 'react';
import { useAsyncValue } from 'react-router-dom';

const useProfile = () => {
  const loaderData = useAsyncValue() as IRouterRootLoaderData;
  const { data, refresh, runAsync } = useRequest(
    async () => {
      try {
        const payload = await getUserProfile();
        return payload;
      } catch (error) {
        console.warn(error);
      }
    },
    {
      cacheKey: 'userProfile',
      manual: true,
    }
  );

  useMount(() => {
    refresh();
  });

  const userProfile = useMemo(() => {
    if (data) return data;
    return loaderData?.userProfile;
  }, [data, loaderData?.userProfile]);

  return {
    userProfile,
    run: refresh,
    runAsync,
  };
};

export default useProfile;
