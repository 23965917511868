import { ReactNode } from 'react';

/*
 * Created Date: August 12th 2024, 11:14:31 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 12th 2024, 3:07:18 pm
 */
export default function Content({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className="p-[14px] pt-[18px] w-[335px] rounded-lg border-[1px] border-[#E2E2E2] bg-[#FFF] [box-shadow:0px_-4px_10px_0px_rgba(0,_0,_0,_0.06)]">
      <div className="text-black text-sm font-changa-one uppercase leading-[14px] tracking-wide mb-[18px]">
        {title}
      </div>
      <div className="flex flex-col gap-[12px]">{children}</div>
    </div>
  );
}
