import { useRef, useState } from 'react';
import { getInvitationRecord, InviteRewardTypeEnum } from '@/api/invite';
import images from '@/const/images';
import { useRequest, useLocalStorageState } from 'ahooks';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Toast, { ToastRef } from '@/components/Toast';
import Header from '@/components/Header';
import useTGInvite from '@/hooks/useTGInvite';
import Modal, { ModalRef } from '@/components/Modal';
import useProfile from '@/hooks/useProfile';
import logEvent, { EventName } from '@/utils/firebase';
import VolumeModal from '@/components/Header/VolumeModal';
import TonBalance from './TonBalance';

export default function Invite() {
  const navigate = useNavigate();

  const { userProfile } = useProfile();

  const { callback, inviteUrl } = useTGInvite(userProfile?.user.tg_user_id);

  const invitationTipModal = useRef<ModalRef>();
  const volumeModal = useRef<ModalRef>();
  const [openInviteType, setOpenInviteType] = useState<'tip' | 'invite'>();
  const [isOpenInvitationTipModal, setIsOpenInvitationTipModal] =
    useLocalStorageState<boolean>('isOpenInvitationTipModal', {
      defaultValue: true,
    });

  const { data: invitationRecord } = useRequest(
    async () => {
      const invitationRecord = await getInvitationRecord();
      return invitationRecord;
    },
    {
      cacheKey: 'inviteRecord',
    }
  );

  const toastRef = useRef<ToastRef>();

  const handleInvite = () => {
    logEvent(EventName.INVITE_INVITE_CLICK, {
      invite_click: 'invite',
    });
    if (isOpenInvitationTipModal) {
      invitationTipModal?.current?.show();
      setOpenInviteType('invite');
    } else {
      callback();
    }
  };

  const handleCopy = () => {
    copy(`${inviteUrl}\n${import.meta.env.VITE_INVITE_DESC}`);
    toastRef.current?.show('success', 'Copy Successful!');
  };

  const goInviteRecord = () => {
    navigate('/inviteRecord');
  };
  const handleInvitationTipOK = () => {
    if (openInviteType === 'invite' && isOpenInvitationTipModal) {
      callback();
      setIsOpenInvitationTipModal(false);
    }
    invitationTipModal?.current?.hide();
  };

  return (
    <div className="w-screen min-h-screen text-center bg-[url('@/assets/images/game/invite/bg.png')] bg-cover pb-[120px]">
      <div className="w-screen h-[60px] relative">
        <Header volumeModal={volumeModal} />
      </div>

      <div className="w-[335px] mx-auto mt-[12px] flex flex-col gap-[16px]">
        <TonBalance invitationRecord={invitationRecord} />
        <div className="p-[13px] bg-[#ffffff] rounded-[10px] shadow-[0px_-3px_0px_0px_#DCDCDC_inset,0px_1px_4px_0px_rgba(0,0,0,0.40)]">
          <h3 className="text-sm text-[#826143] mb-[-5px] font-changa-one flex items-center gap-[5px]">
            EARN REWARDS FOR REFERRALS
            <img
              className="w-[16px] h-[16px] mt-[-2px]"
              width={16}
              height={16}
              src={images.game.invite.ruleIcon}
              onClick={() => {
                invitationTipModal?.current?.show();
                setOpenInviteType('tip');
              }}
            />
          </h3>
          <div
            className="w-[307px] border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_#e2e2e2_inset] flex justify-between items-center box-border mt-[15px] px-[10px] py-[5px] rounded-lg border-solid border-[rgba(255,255,255,0.78)] bg-white font-changa-one"
            onClick={handleInvite}
          >
            <div className="flex items-center">
              <img
                className="w-[60px] h-[60px]"
                width={60}
                height={60}
                src={images.game.invite.rewardIcon1}
              />
              <div className="ml-1.5 flex flex-col">
                <h4 className="text-[14px] text-black m-0">Invite A Friend</h4>
                <div className="gap-[10px] text-[#3E3E3E]">
                  <p className="flex items-center ml-[-5px] m-0 text-[10px]">
                    <img
                      className="w-[15px] h-[15px] ml-[5px] mr-[5px]"
                      width={15}
                      height={15}
                      src={images.game.invite.tonIcon}
                    />
                    +
                    {invitationRecord?.invite_reward
                      ? parseFloat(
                          invitationRecord?.invite_reward.toFixed(4) as string
                        )
                      : 0}
                    <span className="ml-[2px]">TON</span>
                  </p>
                  <p className="flex items-center text-[10px] mt-[5px]">
                    <img
                      className="w-[16px] mr-[2px]"
                      width={25}
                      height={25}
                      src={images.game.invite.energyIcon}
                    />
                    +10
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-[307px] border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_#e2e2e2_inset] flex justify-between items-center box-border mt-[15px] px-[10px] py-[5px] rounded-lg border-solid border-[rgba(255,255,255,0.78)] bg-white font-changa-one"
            onClick={handleInvite}
          >
            <div className="flex items-center">
              <img
                className="w-[60px] h-[60px]"
                width={60}
                height={60}
                src={images.game.invite.rewardIcon2}
              />
              <div className="ml-1.5 flex flex-col">
                <h4 className="text-[14px] text-black m-0">
                  Invite A Friend With TG Premium
                </h4>
                <div className="gap-[10px] text-[#3E3E3E]">
                  <p className="flex items-center ml-[-5px] m-0 text-[10px]">
                    <img
                      className="w-[15px] h-[15px] ml-[5px] mr-[5px]"
                      width={15}
                      height={15}
                      src={images.game.invite.tonIcon}
                    />
                    +
                    {invitationRecord?.invite_premium_reward
                      ? parseFloat(
                          invitationRecord?.invite_premium_reward.toFixed(
                            4
                          ) as string
                        )
                      : 0}
                    <span className="ml-[2px]">TON</span>
                  </p>
                  <p className="flex items-center text-[10px] mt-[5px]">
                    <img
                      className="w-[16px] mr-[2px]"
                      width={25}
                      height={25}
                      src={images.game.invite.energyIcon}
                    />
                    +20
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-3  pt-[10px]">
            <div
              className="w-[213px] h-[34px] shrink-0 [background:var(---,#F75F21)] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-0.8px_0px_1px_rgba(0,0,0,0.13)_inset] rounded-lg flex justify-center items-center text-white font-changa-one cursor-pointer"
              onClick={handleInvite}
            >
              Invite A Friend
            </div>
            <div
              className="cursor-pointer w-[80px] h-[34px] shrink-0 [background:#FFF] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-0.8px_0px_1px_rgba(0,0,0,0.13)_inset] rounded-lg  flex justify-center items-center text-black font-changa-one"
              onClick={handleCopy}
            >
              Copy
            </div>
          </div>
        </div>
      </div>

      {invitationRecord?.list && invitationRecord?.list?.length > 0 && (
        <div className="px-5">
          <h3 className="text-lg text-black m-0 font-changa-one text-left">
            Referral record
            <span className="ml-[5px]">({invitationRecord?.list?.length})</span>
          </h3>

          {invitationRecord?.list?.slice(0, 3)?.map((invite, index) => (
            <div
              key={invite.id}
              className={clsx(
                'w-[335px] box-border border flex justify-between items-center text-sm mt-[15px] p-[10px] pl-[14px] rounded-lg border-solid border-[#ececec] bg-[#f8f8f8] font-changa-one',
                index !== 0 && 'mt-[8px]'
              )}
            >
              <div className="flex items-center gap-2.5 text-black">
                <img
                  className="w-[34px] h-[34px] rounded-[50%] border-2 border-[#B2B2B2]"
                  width={34}
                  height={34}
                  src={images.game.avatarDefault}
                />
                <h4 className="w-[90px] truncate text-left">
                  {invite?.name || 'Anonymous User'}
                </h4>
              </div>
              <div className="flex items-center gap-[10px]">
                {invite?.is_ton_reward === 1 && (
                  <p className="flex items-center text-[#3E3E3E] m-0 w-[75px]">
                    <img
                      className="w-[15px] h-[15px] ml-[5px] mr-[5px]"
                      width={15}
                      height={15}
                      src={images.game.invite.tonIcon}
                    />
                    +
                    {invite?.ton_reward
                      ? parseFloat(invite?.ton_reward.toFixed(4))
                      : 0}
                  </p>
                )}
                {invite?.invite_reward_type === InviteRewardTypeEnum.Coin ? (
                  <p className="flex items-center text-[#3E3E3E] m-0 w-[75px]">
                    <img
                      className="w-[25px] pt-[5px]"
                      width={25}
                      height={25}
                      src={images.common.coin}
                    />
                    +{invite?.is_premium === 1 ? '10000' : '5000'}
                  </p>
                ) : invitationRecord?.list?.length > 20 &&
                  index >= invitationRecord?.list?.length - 20 ? (
                  <p className="flex items-center text-[#3E3E3E] m-0 w-[75px]">
                    <img
                      className="w-[16px] mx-[4px]"
                      width={25}
                      height={25}
                      src={images.game.invite.energyIcon}
                    />
                    +{invite?.is_premium === 1 ? '20' : '10'}
                  </p>
                ) : null}
              </div>
            </div>
          ))}

          {invitationRecord?.list?.length > 3 && (
            <button
              className="font-changa-one text-[12px] text-[#F75F21] flex items-center justify-center mx-auto mt-[15px]"
              onClick={goInviteRecord}
            >
              View more
              <img
                src={images.game.invite.viewMoreIcon}
                className="w-[20px] h-[20px]"
                width={20}
                height={20}
                alt=""
              />
            </button>
          )}
        </div>
      )}

      <Toast ref={toastRef} />

      <Modal ref={invitationTipModal} icon={images.task.modalIcon}>
        <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(158,255,1,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
          <h3 className="text-xl font-changa-one text-black">
            Invitation Success Conditions
          </h3>
          <p className="font-poppins text-slate-400 w-[260px] mx-auto">
            Your friend need to go to [Task] to complete the "following
            announcement" tasks before the invitation is considered successful
          </p>
          <p className="font-poppins text-slate-400 w-[260px] mx-auto mt-[20px]">
            Tips：If you invite more than 20 people, you will no longer receive
            a burger.
          </p>
          <div
            className="flex w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] cursor-pointer rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-changa-one"
            onClick={handleInvitationTipOK}
          >
            OK
          </div>
        </div>
      </Modal>
      <VolumeModal volumeModal={volumeModal} />
    </div>
  );
}
