export const transferSeconds = (seconds: number): string => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondss = seconds % 60;
  return `${minutes < 10 ? '0' + minutes : minutes}:${secondss < 10 ? '0' + secondss : secondss}`;
};

export type DaysType = {
  days: number;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
  isOver?: boolean;
};
export const transferDays = (seconds: number): DaysType => {
  if (seconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0, isOver: true };
  }

  const days = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondss = seconds % 60;

  return {
    days,
    hours: hours < 10 ? '0' + hours : hours,
    minutes: minutes < 10 ? '0' + minutes : minutes,
    seconds: secondss < 10 ? '0' + secondss : secondss,
  };
};

const truncNumber = (num: number)=>{
  return (Math.trunc(num * 100) / 100).toFixed(2);
}

export const transferNumber = (num: number) => {
  if (num >= 1000000000) {
    return [truncNumber(num / 1000000000), 'B'] as const;
  } else if (num >= 1000000) {
    return [truncNumber(num / 1000000), 'M'] as const;
  } else if (num >= 1000) {
    return [truncNumber(num / 1000), 'K'] as const;
  } else {
    return [num + '', ''] as const;
  }
};

export const getInnerWidthRatio = () => {
  return window.innerWidth / 375;
};
export const pxToVw = (px: number) => {
  return `${(px / (window.innerWidth || 375)) * 100}vw`;
};

export function formatCurrency(
  num: number | undefined,
  zero?: boolean
): string {
  if (num === undefined || num === 0) return !!zero ? '0' : '000,000';
  let intStr = Math.abs(num).toString();

  let formattedIntPart = '';
  while (intStr.length > 3) {
    formattedIntPart = ',' + intStr.slice(-3) + formattedIntPart;
    intStr = intStr.slice(0, -3);
  }
  formattedIntPart = intStr + formattedIntPart;

  return num > 0 ? formattedIntPart : '-' + formattedIntPart;
}

export function encodeBase58(num: number) {
  const base58Chars =
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

  if (num === 0) return base58Chars[0];
  let encoded = '';
  while (num > 0) {
    let remainder = num % 58;
    num = Math.floor(num / 58);
    encoded = base58Chars[remainder] + encoded;
  }
  return encoded;
}

export function isValidERC20Address(address: string) {
  const erc20AddressRegex = /^0x[a-fA-F0-9]{40}$/;
  return erc20AddressRegex.test(address);
}

export function tTansfer(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h:${minutes}m`;
}

export function formatAddress(address?: string) {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-7)}`;
}

export function formatFloatNumber(num: number) {
  return Math.round(num * 100);
}
